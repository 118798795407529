import { useEffect, useMemo, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import Modal from "react-modal";
import { useLocation } from "react-router";
import { toast } from "react-toastify";
import Logo from "../../../assets/check.png";
import AnonymousProfilePhoto from "../../../assets/userAnonymous.jpeg";
import CustomButton from "../../../components/CustomButton";
import CustomLabel from "../../../components/CustomLabel";
import CustomTable from "../../../components/CustomTable";
import PageStructure from "../../../components/PageStructure";
import { customStyles } from "../../../components/Shared";
import { HeaderPage } from "../../../components/Shared/HeaderPage";
import { LogoWrapper } from "../../../components/SideMenu/style";
import api from "../../../service/api";
import { ListEmptyLabel, ListEmptyWrapper } from "../../Today/style";
import {
  HeaderTitle,
  HeaderWrapper,
  ProfileActionsWrapper,
  ProfileHeader,
  ProfileHeaderTop,
  ProfileImageWrapper,
} from "./style";
import { getGroup, hasAdmin } from "../../../service/token.service";
import { animated, useSpring } from "react-spring";
import UpdatePatient from "../../../modals/Patient/Update";
import { useNavigate } from "react-router-dom";
import { differenceInDays } from "date-fns";
import { jwtDecode } from "jwt-decode";

interface PatientData {
  name: string;
  document: string;
  address: string;
  email: string;
  phone: string;
  [key: string]: any;
}


export const PatientProfile = () => {
  const location = useLocation();
  const { patient, hidesidebar } = location?.state as {
    patient?: any;
    hidesidebar?: boolean;
  };

  const [patientData, setPatientData] = useState<PatientData>({
    name: '',
    document: '',
    address: '',
    email: '',
    phone: ''
  });
  
  const [activity, setActivity] = useState<string | undefined>(undefined);
  const [modalIsOpen, setIsOpen] = useState(false);

  const [data, setData] = useState<any>([]);
  const [dataRegister, setDataRegister] = useState<any>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();

  interface TodoItem {
    type: {
      name: string;
    };
    status: string;
    date: string;
    patient: {
      name: string;
    };
    objective: string;
    subject: string;
    professional: {
      name: string;
    };
  }

  useEffect(() => {
    fetchAppointmentHistoric();
  }, []);

  const fetchAppointmentHistoric = () => {
    let toastLoading = toast.loading("Carregando consultas...", {
      className: "toast-loading",
    });

    api
      .get(`/user/appointment/${patient.id}/`)
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        toast.error("Erro ao carregar as consultas!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  const fetchRegisterHistoric = () => {
    let toastLoading = toast.loading("Carregando registros...", {
      className: "toast-loading",
    });

    api
      .get(`/todo/?patient=${patient.id}&answer_isnull=false`)
      .then((response) => {
        const formattedData = response.data.map((item: TodoItem) => {
          return {
            ...item,
            date: new Date(item.date).toLocaleDateString("pt-BR"), // ou outra localização desejada
          };
        });
        setDataRegister(formattedData);
      })
      .catch((error) => {
        toast.error("Erro ao carregar os registros!");
      })
      .finally(() => {
        toast.dismiss(toastLoading);
      });
  };

  useEffect(() => {
    fetchRegisterHistoric();
  }, []);

  useEffect(() => {
    const fetchPatientData = async () => {
      if (patient.id) {
        try {
          const res = await api.get(`/clients/${patient.id}`);
          console.log(res.data)
          setPatientData(res.data);
        } catch (error) {
          console.error('Erro ao buscar dados do paciente:', error);
        }
      }
    };
  
    fetchPatientData();
  }, []);

  const fetchPatientActivity = async (patientId: number) => {
    try {
      const response = await api.get(`/user/latest-appointment/${patientId}/`);
      return response.data;
    } catch (error) {
      console.error("Erro ao carregar histórico de consultas:", error);
      return [];
    }
  };

  const checkPatientActivity = async (patientId: number, professionalId: number) => {
    try {
      const appointments = await fetchPatientActivity(patientId);
      if (appointments === null) return "Inativo";

      const professional = await api.get(`/professionals/${professionalId}`);
      const inactiveDaysLimit = professional.data.inactive_days;

      const daysSinceLastAppointment = differenceInDays(
        new Date(),
        new Date(appointments.start_time)
      );

      return daysSinceLastAppointment <= inactiveDaysLimit ? "Ativo" : "Inativo";
    } catch (error) {
      console.error("Erro ao verificar atividade do paciente:", error);
      return "Inativo";
    }
  };

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      const tokenData = jwtDecode(token) as any;
      const userId = tokenData?.user_id;

      if (userId !== undefined) {
        const fetchActivity = async () => {
          const activity = await checkPatientActivity(patient.id, userId);
          setActivity(activity);
        };

        fetchActivity();
      }
    }
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Local",
        accessor: "establishment.alias",
      },
      {
        Header: "Profissional",
        accessor: "professional.name",
      },
      {
        Header: "Consulta",
        accessor: "service.name",
      },
      {
        Header: "Status do pagamento",
        accessor: "status.name",
      },
      {
        Header: "Inicio da consulta",
        accessor: "start_time",
      },
      {
        Header: "Fim da consulta",
        accessor: "end_time",
      },
    ],
    []
  );

  const historyColumns = useMemo(
    () => [
      {
        Header: "Tipo",
        accessor: "type.name",
      },
      {
        Header: "Resposta",
        accessor: "answer",
      },
      {
        Header: "Data do Contato",
        accessor: "updated_at",
        Cell: ({ value }: any) => {
          const date = new Date(value);
          const formattedDate = date.toLocaleDateString("pt-BR", {
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          });
          const formattedTime = date.toLocaleTimeString("pt-BR", {
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          });
          return <span>{`${formattedDate} às ${formattedTime}`}</span>;
        },
      },
      {
        Header: "Objetivo",
        accessor: "objective",
      },
      {
        Header: "Assunto",
        accessor: "subject",
      },
      {
        Header: "Profissional",
        accessor: "professional.name",
      },
    ],
    []
  );

  const editFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: showEditModal ? 1 : 0 },
  });

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };
  return (
    <PageStructure hidesidebar={hidesidebar}>
      <Modal
        isOpen={showEditModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={editFade}>
          <UpdatePatient
            closeModal={closeEditModal}
            updatePatients={() => navigate("/patients")}
            patient={patient}
          />
        </animated.div>
      </Modal>
      <HeaderPage title="" />
      <ProfileHeader>
        <ProfileHeaderTop>
          <ProfileImageWrapper
            src={patient?.photo || AnonymousProfilePhoto}
            alt="Foto de perfil"
          />
          <CustomLabel label="Nome" info={patientData.name} />
          <CustomLabel label="CPF" info={patientData.document} />
          <CustomLabel label="Endereço" info={patientData.address} />
          <CustomLabel label="Email" info={patientData.email} />
          <CustomLabel label="Telefone" info={patientData.phone} />
          {activity !== undefined && (
            <CustomLabel label="" info={activity} infoBold />
          )}
          <ProfileActionsWrapper>
            {getGroup() == "gestor" && (
              <CustomButton
                theme="addPrimary"
                onClick={() => {
                  navigate("/medicalRecordHistory", {
                    state: {
                      patient: patient,
                    },
                  });
                }}
              >
                Prontuário
              </CustomButton>
            )}
            <CustomButton
              theme="reschedule"
              onClick={() => {
                setIsOpen(true);
              }}
            >
              Contatos
            </CustomButton>
            <CustomButton
              onClick={() => setShowEditModal(true)}
              disabled={!hasAdmin()}
              theme="update"
            >
              Editar
            </CustomButton>
            <CustomButton disabled={true} theme="cancel">
              Bloquear
            </CustomButton>
          </ProfileActionsWrapper>
        </ProfileHeaderTop>
      </ProfileHeader>
      <HeaderWrapper>
        <HeaderTitle>Histórico de consultas</HeaderTitle>
      </HeaderWrapper>
      {data?.length > 0 ? (
        <>
          <CustomTable
            maxHeight={"calc(100vh - 496px)"}
            columns={columns}
            columnMinWidths={{}}
            data={data}
          />
        </>
      ) : (
        <ListEmptyWrapper style={{ marginTop: "10px" }}>
          <LogoWrapper src={Logo} alt="Logo" />
          <ListEmptyLabel> Sem consultas anteriores! </ListEmptyLabel>
        </ListEmptyWrapper>
      )}

      {/*////////////  MODAL PARA REGISTRAR //////////*/}

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={{
          ...customStyles,
          content: {
            ...customStyles.content,
            width: "90%",
          },
        }}
      >
        <HeaderWrapper>
          <HeaderTitle>Registros de Contatos</HeaderTitle>
          <IoCloseOutline
            size={22}
            onClick={closeModal}
            color="#919EAB"
            cursor={"pointer"}
          />
        </HeaderWrapper>
        {dataRegister?.length > 0 ? (
          <CustomTable
            maxHeight={"calc(100vh - 100px)"}
            columns={historyColumns}
            columnMinWidths={{}}
            data={dataRegister}
          />
        ) : (
          <ListEmptyWrapper style={{ marginTop: "10px" }}>
            <LogoWrapper src={Logo} alt="Logo" />
            <ListEmptyLabel> Sem registros anteriores! </ListEmptyLabel>
          </ListEmptyWrapper>
        )}
      </Modal>
    </PageStructure>
  );
};

export default PatientProfile;
