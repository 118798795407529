import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import jsPDF from "jspdf";
import 'jspdf-autotable';
import { useEffect, useRef, useState } from "react";
import { AiOutlineInbox } from "react-icons/ai";
import { HiMagnifyingGlass } from "react-icons/hi2";
import { IoCloudDownloadOutline, IoFilterOutline } from "react-icons/io5";
import Modal from "react-modal";
import { GaugeOccupation } from "../../components/Charts/Pizza";
import { Profit } from "../../components/Charts/Profit";
import { ContentMoney } from "../../components/ContentMoney";
import CustomDropdown from "../../components/CustomDropDown";
import CustomInput from "../../components/CustomInput";
import { HistoryMoviment } from "../../components/HistoryMoviments";
import { Ocuppation } from "../../components/Occupation";
import PageStructure from "../../components/PageStructure";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import api from "../../service/api";
import { formatDecimalValues } from "../../utils/formatDecimalValues";
import { getFirstAndLastDayOfWeek } from "../../utils/getFirstAndLastDayOfWeek";
import { groupTransactionsByDate } from "../../utils/groupTransactionsByDate";
import { ListEmptyLabel, ListEmptyWrapper } from "../Today/style";
import { DateTitle } from "../Wallet/syle";
import { AggregatedData, Appointment } from "./models/Appointment";
import Logo from "../../assets/bridges.png";
import {
  Button,
  ChartWrapper,
  DataHeaderWrapper,
  DataWrapper,
  HeaderData,
  IconButton,
  LeftContentHeader,
  MovimentListWrapper,
  PageContent,
  RightContentHeader,
  SideHistoryContent,
  SubHeaderWrapper,
  SubTitle,
  SubTitleInfosWrapper,
  TextButton,
  ValuesContent,
  customStyles,
} from "./style";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";

interface Transaction {
  id: number;
  title: string;
  type: string;
  description: string;
  amountValue: string;
  date: string;
}

const options = [
  'Selecione...',
  'Similaridade',
  'Profissional',
  'Paciente',
  'Estabelecimento',
  'Serviço',
  'Observação',
  'Valor'
]

export const Control = () => {
  const [transactionsByDate, setTransactionsByDate] = useState<{
    [date: string]: Transaction[];
  }>({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [sortChart, setSortChart] = useState("");
  const [receiveValue, setReceiveValue] = useState("");
  const [outValue, setOutValue] = useState("");
  const [pendencyValue, setPendencyValue] = useState("");
  const [filterValue, setFilterValue] = useState("Similaridade");
  const [weeklyChartData, setWeeklyChartData] = useState<any[]>([]);
  const [monthChartData, setMonthChartData] = useState<any[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [startDate, setStartDate] = useState<any>(null);
  const [endDate, setEndDate] = useState<any>(null);
  const [professionals, setProfessionals] = useState<[] | any>([]);
  const [professionalSelectedId, setProfessionalSelectedId] = useState<any>(0);
  const [services, setServices] = useState<[]>([])
  const [occupationRate, setOccupationRate] = useState<any>({})

  const paymentsBackup = useRef<any>(null);
  const lastRenderTimeRef = useRef<number>(Date.now());
  const [updatedAt, setUpdatedAt] = useState<string>("0s");

  const [reportData, setReportData] = useState({
    totalPaidAmount: '0',
    total_comission_real_clinic: '0',
    totalTaxValue: '0',
    totalNetValue: '0',
  });

  const fetchHistory = () => {
    if (startDate && endDate)
      api.get(`/payments?start_date=${startDate}&end_date=${endDate}`).then((resp) => {
        const mappedReturn = groupTransactionsByDate(resp.data);
        paymentsBackup.current = mappedReturn || {};
        setTransactionsByDate(mappedReturn);
      });

    if (startDate && endDate && professionalSelectedId)
      api.get(`/payments?start_date=${startDate}&end_date=${endDate}&professional_id=${professionalSelectedId}`).then((resp) => {
        const mappedReturn = groupTransactionsByDate(resp.data);
        paymentsBackup.current = mappedReturn || {};
        setTransactionsByDate(mappedReturn);
      });
  };

  const getOccupationRate = () => {
    api.get(`/ocupation/rate/${startDate}/${endDate}/0/`).then((resp) => {
      setOccupationRate(resp.data)
    });
  }

  useEffect(() => {
    lastRenderTimeRef.current = Date.now();

    const today = new Date();
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);

    const formatDate = (date: any) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    setEndDate(formatDate(today));
    setStartDate(formatDate(sevenDaysAgo));

    fetchProfessionals();
    fetchHistory();
  }, []);

  useEffect(() => {
    if (paymentsBackup.current) {
      handleFilter('');
    }

    if (startDate && endDate) {
      fetchWeeklyData();
      fetchHistory();
      getOccupationRate();
    }
  }, [startDate, endDate, professionalSelectedId]);

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const mapAppointmentsByDay = (appointments: Appointment[]): { [key: string]: AggregatedData } => {
    return appointments.reduce((acc, appointment) => {
      const day = appointment.start_time.split('T')[0];

      if (!acc[day]) {
        acc[day] = {
          professional_commission: 0,
          price: 0,
          clinic_commission: 0,
          status: appointment.status || ''
        };
      }

      acc[day].professional_commission += appointment.professional_commission;
      acc[day].price += appointment.price;
      acc[day].clinic_commission += appointment.clinic_commission;

      return acc;
    }, {} as { [key: string]: AggregatedData });
  };

  const convertToArray = (data: { [key: string]: AggregatedData }) => {
    const dates: string[] = [];
    const professional_commissions: number[] = [];
    const prices: number[] = [];
    const clinic_commissions: number[] = [];

    const days = Object.keys(data).sort();

    const monthNames = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];

    if (days.length > 60) {
      const monthlyData: { [key: string]: { professional_commission: number, price: number, clinic_commission: number, count: number } } = {};

      for (const day of days) {
        const month = day.substring(0, 7);
        if (!monthlyData[month]) {
          monthlyData[month] = { professional_commission: 0, price: 0, clinic_commission: 0, count: 0 };
        }
        monthlyData[month].professional_commission += data[day].professional_commission;
        monthlyData[month].clinic_commission += data[day].clinic_commission;
        if (data[day].status == 'Pago') {
          monthlyData[month].price += data[day].price;
        }
        monthlyData[month].count += 1;
      }

      for (const month in monthlyData) {
        const [year, monthIndex] = month.split('-');
        dates.push(`${monthNames[parseInt(monthIndex) - 1]}/${year}`);
        professional_commissions.push(monthlyData[month].professional_commission);
        prices.push(monthlyData[month].price);
        clinic_commissions.push(monthlyData[month].clinic_commission);
      }

    } else {
      for (const day of days) {
        const [year, month, date] = day.split('-');
        const formattedDate = `${parseInt(date)} de ${monthNames[parseInt(month) - 1]}`;
        dates.push(formattedDate);
        professional_commissions.push(data[day].professional_commission);
        if (data[day].status == 'Pago') {
          prices.push(data[day].price);
        } else {
          prices.push(0);
        }
        clinic_commissions.push(data[day].clinic_commission);
      }
    }

    return {
      dates,
      professional_commissions,
      prices,
      clinic_commissions
    };
  };

  const fetchProfessionals = () => {
    api.get('/professionals/').then(response => {
      setProfessionals(response.data)
    }).catch(err => console.log(err))
  }

  const fetchWeeklyData = () => {
    let days = getFirstAndLastDayOfWeek();
    if (startDate) days.firstDay = startDate;
    if (endDate) days.lastDay = endDate;

    api.get(`/report/${days.firstDay}/${days.lastDay}/${professionalSelectedId}/`).then(async (resp) => {
      setReceiveValue(formatDecimalValues(resp.data.total_paid))
      setOutValue(formatDecimalValues(resp.data.total_comission_real_clinic))
      setPendencyValue(formatDecimalValues(resp.data.total_comission_real_professional))

      const newReportData = {
        totalPaidAmount: formatDecimalValues(resp.data.total_paid),
        total_comission_real_clinic: formatDecimalValues(resp.data.total_comission_real_clinic),
        totalTaxValue: formatDecimalValues(resp.data.total_tax_value),
        totalNetValue: formatDecimalValues(resp.data.total_net_value),
      };

      setServices(resp.data.services)
      setReportData(newReportData)

      const appointments_mapped = mapAppointmentsByDay(resp.data?.services)

      const convertedToArray = convertToArray(appointments_mapped)

      setSortChart("semanal")

      const weeklyChartData = [
        { name: "Total Pago", data: convertedToArray.prices },
        { name: "Comissão total da clinica", data: convertedToArray.clinic_commissions },
        { name: "Comissao total do profissional", data: convertedToArray.professional_commissions },
      ]

      setMonthChartData(weeklyChartData)
      setLabels(convertedToArray.dates)
    });
  };

  const translateToKeyInObject = (option: string) => {
    switch (option) {
      case "Profissional":
        return "professional";
      case "Paciente":
        return "patient";
      case "Estabelecimento":
        return "establishment";
      case "Serviço":
        return "service";
      case "Observação":
        return "obs";
      case "Valor":
        return "value";
      default:
        return "";
    }
  };

  const containsSearchTerm = (obj: any, searchTerm: string, searchInto?: string): boolean => {
    for (let key in obj) {
      const keyToFilter = translateToKeyInObject(filterValue)!;

      if (filterValue !== "Similaridade" && key !== "appointment" && searchInto !== keyToFilter) {
        if (key !== keyToFilter) continue;
      }

      if (typeof obj[key] === "string" && obj[key].toLowerCase().includes(searchTerm.toLowerCase())) {
        return true;
      }

      if (typeof obj[key] === "object" && obj[key] !== null) {
        if (containsSearchTerm(obj[key], searchTerm, key)) {
          return true;
        }
      }
    }

    return false;
  };


  const handleProfessionalSelected = (id: number) => {
    if (id === professionalSelectedId) {
      setProfessionalSelectedId(0);
    } else
      setProfessionalSelectedId(id);
  }

  const handleFilter = (searchTerm: string) => {
    if (!paymentsBackup.current) {
      return;
    }

    if (searchTerm === '' && startDate === '' && endDate === '') {
      setTransactionsByDate(paymentsBackup.current);
      fetchWeeklyData();
      return;
    }

    let filteredKeys = {} as any;

    Object.keys(paymentsBackup.current).forEach(item => {
      const transactions = paymentsBackup.current[item].filter((transaction: Transaction) => {
        const transactionDate = new Date(transaction.date);
        const start = startDate ? new Date(startDate) : null;
        const end = endDate ? new Date(endDate) : null;

        const isWithinDateRange = (!start || transactionDate >= start) && (!end || transactionDate <= end);
        const matchesSearchTerm = containsSearchTerm(transaction, searchTerm);

        return isWithinDateRange && matchesSearchTerm;
      });

      if (transactions.length > 0) {
        filteredKeys[item] = transactions;
      }
    });

    setTransactionsByDate(filteredKeys);


    fetchWeeklyData();
  };

  const generatePDF = () => {
    const formatDateTime = (dateString: string) => {
      const date = parseISO(dateString);
      return format(date, 'dd/MM/yyyy HH:mm', { locale: ptBR });
    };

    const doc = new jsPDF('landscape');

    const tableColumnHeaders = [
      "Serviço",
      "Preço",
      "Data",
      "Local",
      "Paciente",
      "Profissional",
      "Status",
      "Valor Pago",
      "% de Taxa",
      "Valor da Taxa",
      "Valor Liquido",
      "Comissão da Clinica",
      "Comissão do Profissional",
    ];

    const tableRows: any[] = [];

    services.forEach((service: any) => {
      const rowData = [
        service.service,
        `R$ ${Number(service.price).toFixed(2)}`,
        formatDateTime(service.start_time),
        service.establishment,
        service.patient,
        service.professional,
        service.status,
        `R$ ${Number(service.paid_amount).toFixed(2)}`,
        `${Number(service.tax_percentage).toFixed(2)}%`,
        `R$ ${Number(service.tax_value).toFixed(2)}`,
        `R$ ${Number(service.net_value).toFixed(2)}`,
        `R$ ${Number(service.clinic_commission).toFixed(2)}`,
        `R$ ${Number(service.professional_commission).toFixed(2)}`,
      ];

      tableRows.push(rowData);
    });

    let isFirstPage = true;

    (doc as any).autoTable({
      head: [tableColumnHeaders],
      body: tableRows,
      startY: 20,
      theme: 'grid',
      styles: { fontSize: 10 },
      headStyles: { fillColor: [18, 112, 252], textColor: 255, halign: 'center', valign: 'middle' },
      bodyStyles: { textColor: 50 },
      columnStyles: {
        0: { cellWidth: 20 },
        1: { cellWidth: 15 },
        2: { cellWidth: 25 },
        3: { cellWidth: 20 },
        4: { cellWidth: 30 },
        5: { cellWidth: 30 },
        6: { cellWidth: 30 },
      },
      didDrawPage: (data: any) => {
        if (isFirstPage) {
          const pageWidth = doc.internal.pageSize.width;
          const pageHeight = doc.internal.pageSize.height;
          const logoWidth = 50;
          const logoHeight = 15;
          const logoWidthReduced = logoWidth * 0.8;
          const logoHeightReduced = logoHeight * 0.8;

          const marginLeft = 14;
          const logoMarginRight = 12;

          const titleYPosition = (pageHeight / 9) - 10;

          doc.setFont("helvetica", "bold");
          doc.setTextColor(0, 0, 0);
          doc.text("Relatório de Serviços", marginLeft, titleYPosition);

          doc.addImage(Logo, "PNG", pageWidth - logoWidthReduced - logoMarginRight, 5, logoWidthReduced, logoHeightReduced);

          isFirstPage = false;
        }
      },
      didDrawCell: (data: any) => {
        const columnIndex = 6;

        if (data.row.index >= 0 && data.column.index === columnIndex) {
          const status = data.row.raw[columnIndex];

          if (status === "Pago") {
            doc.setFillColor(129, 226, 151);
          } else if (status === "Status") {
            doc.setFillColor(18, 112, 252);
          } else {
            doc.setFillColor(255, 235, 59);
          }

          doc.rect(data.cell.x, data.cell.y, data.cell.width, data.cell.height, 'F');

          status !== "Status" ? doc.setTextColor(0, 0, 0) : doc.setTextColor(255);
          doc.text(
            status,
            data.cell.x + data.cell.padding('horizontal'),
            data.cell.y + data.cell.height / 2,
            { baseline: 'middle' }
          );
        }
      },
    });

    const finalYPosition = doc.internal.pageSize.height - 20;

    doc.setFontSize(12);
    doc.setFont("helvetica", "normal");

    const startX = 10;
    const spacing = 70;

    doc.setDrawColor(200, 200, 200);
    doc.line(10, finalYPosition - 10, doc.internal.pageSize.width - 10, finalYPosition - 10);

    doc.setFont("helvetica", "bold");
    doc.text("Resumo:", startX, finalYPosition - 5);
    doc.setFont("helvetica", "normal");

    doc.text(`Total Recebido Clínica:`, startX, finalYPosition + 5);
    doc.text(`R$ ${reportData.totalPaidAmount}`, startX + 45, finalYPosition + 5);

    doc.text(`Comissão Total Clínica:`, startX + spacing, finalYPosition + 5);
    doc.text(`R$ ${reportData.total_comission_real_clinic}`, startX + spacing + 45, finalYPosition + 5);

    doc.text(`Total Pago por Taxas:`, startX + 2 * spacing, finalYPosition + 5);
    doc.text(`R$ ${reportData.totalTaxValue}`, startX + 2 * spacing + 42, finalYPosition + 5);

    doc.text(`Total Líquido Clínica:`, startX + 3 * spacing, finalYPosition + 5);
    doc.setFont("helvetica", "bold")
    doc.text(`R$ ${reportData.totalNetValue}`, startX + 3 * spacing + 40, finalYPosition + 5);

    doc.save('services.pdf');
  };

  const getOccupationValue = (item: any) => {
    if (occupationRate) {
      return occupationRate?.details?.reduce((i: any) => i.professional_id === item.id)?.ocupacao;
    } else {
      return 0;
    }
  }

  const getElapsedTime = () => {
    const now = Date.now();

    const elapsedTime = (now - lastRenderTimeRef.current) / 1000;

    const minutes = Math.floor(elapsedTime / 60);
    const seconds = Math.floor(elapsedTime % 60);

    if (minutes === 0) {
      return `${seconds}s`;
    }

    return `${minutes}m`;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setUpdatedAt(getElapsedTime());
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
      </Modal>
      <PageStructure>
        <HeaderPage
          title="Relatórios"
          buttonRight={
            <Button onClick={generatePDF}>
              <IconButton>
                <IoCloudDownloadOutline />
              </IconButton>
              <TextButton>Download Relatório</TextButton>
            </Button>
          }
        />
        <SubHeaderWrapper>
          <LeftContentHeader>
            <SubTitleInfosWrapper>
              <SubTitle>Expectativas</SubTitle>
            </SubTitleInfosWrapper>
            <HeaderData>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span style={{ margin: '0 8px' }}>de</span>
                <div style={{ width: '140px', height: '30px' }}>
                  <CustomInput
                    name="start_date"
                    type="date"
                    value={startDate}
                    inputStyle={{ height: '30px' }}
                    onChange={e => setStartDate(e.currentTarget.value)}
                  />
                </div>
                <span style={{ margin: '0 8px' }}>até</span>
                <div style={{ width: '140px', height: '30px' }}>
                  <CustomInput
                    name="start_date"
                    type="date"
                    value={endDate}
                    inputStyle={{ height: '30px' }}
                    onChange={e => setEndDate(e.currentTarget.value)}
                  />
                </div>
              </div>
            </HeaderData>
          </LeftContentHeader>
          <RightContentHeader>
            <CustomInput
              onChange={(e) => handleFilter(e.currentTarget.value)}
              name=""
              disableBorder
              placeholder="Pesquisar..."
              leftIcon={
                <HiMagnifyingGlass size={20} color="var(--primary-icon-color)" />
              }
            />
            <div style={{ width: "40%" }}>
              <CustomDropdown
                disableBorder
                setSelectedOption={(value) => {
                  setFilterValue(value);
                }}
                selectedOption={filterValue}
                options={options}
                allowCreate={false}
                leftIcon={
                  <IoFilterOutline size={16} color="var(--primary-icon-color)" />
                }
              />
            </div>
          </RightContentHeader>
        </SubHeaderWrapper>
        <PageContent>
          <DataHeaderWrapper>
            <DataWrapper>
              <ValuesContent>
                <ContentMoney
                  title="Total Pago"
                  amount={receiveValue}
                  icon={<FiArrowUp size={20} color="#fff" />}
                  updatedAt={updatedAt}
                />
                <ContentMoney
                  title="Comissão da clinica"
                  amount={outValue}
                  icon={<FiArrowDown size={20} color="#fff" />}
                  updatedAt={updatedAt}
                />
                <ContentMoney
                  title="Comissao do profissional"
                  amount={pendencyValue}
                  icon={<FiArrowUp size={20} color="#fff" />}
                  updatedAt={updatedAt}
                />
              </ValuesContent>
              <ChartWrapper>
                <Profit data={monthChartData} labelsExtern={labels} />
              </ChartWrapper>
              <ChartWrapper>
                <SubTitle>Movimentações</SubTitle>
                <MovimentListWrapper>
                  {Object.keys(transactionsByDate).length ? Object.keys(transactionsByDate).map((item) => {
                    return (
                      <div key={item}>
                        <DateTitle>{item}</DateTitle>
                        <HistoryMoviment date={transactionsByDate[item]} />
                      </div>
                    );
                  })
                    :
                    <ListEmptyWrapper style={{ marginTop: '10px' }}>
                      <AiOutlineInbox style={{ fontSize: "24px", color: "#ccc" }} />
                      <ListEmptyLabel> Sem registros no período! </ListEmptyLabel>
                    </ListEmptyWrapper>
                  }
                </MovimentListWrapper>
                <GaugeOccupation data={monthChartData} labelsExtern={labels} />
              </ChartWrapper>
            </DataWrapper>
          </DataHeaderWrapper>
          <SideHistoryContent>
            <SubTitle>Profissionais</SubTitle>
            <MovimentListWrapper>
              {professionals?.map((item: any) => {
                return (
                  <Ocuppation
                    occupation={`Nº conselho: ${item?.n_conselho}`}
                    professionalName={item?.name}
                    img={item.photo}
                    occupationValue={getOccupationValue(item)}
                    occupationFinalValue={100}
                    isSelected={item.id === professionalSelectedId}
                    onClick={() => {
                      handleProfessionalSelected(item.id)
                    }}
                  />
                );
              })}
            </MovimentListWrapper>
          </SideHistoryContent>
        </PageContent>
      </PageStructure>
    </>
  );
};
