import CustomButton from "../../components/CustomButton";
import CustomInput from "../../components/CustomInput";
import PageStructure from "../../components/PageStructure";
import PatientProfile from "../../assets/user-profile.png";
import { AnimatePresence, motion } from "framer-motion";
import * as S from "./styles";
import { useEffect, useState } from "react";
import CustomTextArea from "../../components/CustomTextArea";
import ExpandableSection from "../../components/ExpandableSection";
import ButtonOptionList from "../../components/ButtonOptionList";
import { MultiOptionButtonList } from "../../components/MultiOptionButtonList";
import { PatologyList } from "./mock/PatolgyList";
import CustomDropdown from "../../components/CustomDropDown";
import MetricIcon from "../../assets/fita-metrica.png";
import Kg from "../../assets/kg.png";
import CheckList from "../../assets/check-list.png";
import Composition from "../../assets/composition.png";
import LifeStyle from "../../assets/lifestyle.png";
import Genoma from "../../assets/genoma.png";
import Avaliacao from "../../assets/viabilidade.png";
import SistemaUrinario from "../../assets/sistema-urinario.png";
import Comida from "../../assets/alimentos.png";
import { jwtDecode } from "jwt-decode";
import { MetricsDTO } from "./dtos/MetricsDTO";
import { AntropometryDTO } from "./dtos/AntropometryDTO";
import { AnamneseDTO } from "./dtos/AnamneseDTO";
import api from "../../service/api";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import { getProfessionalId } from "../../service/token.service";
import PatientSummary, { calculateAgeDetails } from "./components/PatientSummary";
import { FaAngleDown, FaAngleUp, FaSave } from "react-icons/fa";
import Model, { IExerciseData, IMuscleStats } from 'react-body-highlighter';
import { SegmentList } from "./mock/SegmentList";
import React from "react";

export const MedicalRecord = () => {
  const formatDate = (dateString: string) => {
    const splitedDate = dateString.split("T");
    return splitedDate[0];
  };
  const location = useLocation();
  const navigate = useNavigate();
  const { patient, pageType, recordData } = location?.state as {
    patient?: any;
    pageType: string;
    recordData: any;
  };

  const [dadosPaciente, setDadosPaciente] = useState<DadosPaciente | null>(null);
  const [antropometriaExpandable, setAntropometriaExpandable] = useState(true);
  const [anamneseExpandable, setAnamneseExpandable] = useState(true);
  const [hasMealsAwayFromHome, setHasMealsAwayFromHome] = useState(false);
  const [appetiteSelectedOption, setAppetiteSelectedOption] =
    useState("normal");
  const [foodRestrictionSelectedOption, setFoodRestrictionSelectedOption] =
    useState("nenhum");
  const [selectedChewing, setSelectedChewing] = useState("normal");
  const [stoolShape, setStoolShape] = useState("Teste");
  const [evacuationFrequency, setEvacuationFrequency] = useState("pordia");
  const [shitColorOption, setShitColorOption] = useState("marrom");
  const [intestionalHabitSelectedOption, setIntestionalHabitSelectedOption] =
    useState("normal");
  const [hydroSelectedOption, setHydroSelectedOption] =
    useState("1bemhidratado");
  const [bodyType, setBodyType] = useState("pregascutaneas");
  const [selectedProtocol, setSelectedProtocol] = useState("Teste");
  const [selectedSegmentList, setSelectedSegmentList] = useState<string[]>([]);
  const [selectedPathologyList, setSelectedPathologyList] = useState<string[]>(
    []
  );
  const [userRole, setUserRole] = useState("");
  const [metricObj, setMetricObj] = useState<MetricsDTO>({
    date: formatDate(new Date().toISOString()),
    weight: 0,
    height: 0,
    fatFreeMass: 0,
    skeletalMuscleMass: 0,
    fatMass: 0,
    visceralFatLevel: 0,
    pgc: 0,
    obesityGrade: 0,
    tmb: 0,

    armRelaxedRight: 0,
    armContractedRight: 0,
    chest: 0,
    waist: 0,
    abdomen: 0,
    hip: 0,
    thigh: 0,
    calf: 0,
    rcq: 0,
    rce: 0,

    abdomenDobras: 0,
    chestDobras: 0,
    supraIliaca: 0,
    axilarMedia: 0,
    thighDobras: 0,
    triceps: 0,
    subscapular: 0,
    dc: 0,
    percentageG: 0,
  });

  const [antropometryObj, setAntropometryObj] = useState<AntropometryDTO>({
    neck: 0,
    shoulder: 0,
    breastPlate: 0,
    waist: 0,
    abdomen: 0,
    hip: 0,
    right_calf: 0,
    left_calf: 0,
    right_thigh: 0,
    left_thigh: 0,
    right_proximal_thigh: 0,
    left_proximal_thigh: 0,
    composition_body: {
      abdominal: 0,
      biceps: 0,
      chest: 0,
      medial_calf: 0,
      middle_axiliary: 0,
      protocol: "",
      subscapularis: 0,
      suprailiaca: 0,
      thigh: 0,
      triceps: 0,
      type: bodyType,
    },
  });
  const [anamneseObj, setAnamneseObj] = useState<AnamneseDTO>({
    clinical_case: "",
    protocol: [""],
    ideal_weight: 0,
    target_weight: 0,
    date: formatDate(new Date().toISOString()),
    lifeHabits: {
      foodRestriction: "",
      drinkAlcohol: false,
      smoking: false,
      mealsAwayFromHome: false,
      mealDescription: "",
      numberOfPeopleLives: 0,
      whoDoesHouseholdShopping: "",
      whereYouShop: "",
      howManyTimes: 0,
      takeChildren: false,
      litersOilUsedPerMonth: 0,
      kiloSaltUsedPerMonth: 0,
    },
    pathology: {
      types: [""],
      otherPathologies: "",
      medicines: "",
      exams: "",
      familyHistory: "",
      observation: "",
    },
    clinicalAssessment: {
      appetite: "",
      chewing: "",
      intestionalHabit: "",
      evacuationFrequency: 0,
      format: "",
      frequencyType: "",
      useLaxative: false,
      stoolColor: "",
    },
    urinaryHabits: {
      describe: "",
      waterIntake: "",
      urinaryHydratation: "",
      observation: "",
    },
    eatingHabits: {
      foodSuplements: "",
      foodAllergy: "",
      foodIntolerance: "",
      foodAversions: "",
      observation: "",
    },
  });

  const [bodyData, setBodyData] = useState<IExerciseData[]>([]);

  useEffect(() => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      const tokenData = jwtDecode(token) as any;
      setUserRole(tokenData.groups[0] || "");
    }
  }, []);

  const handleSaveRecord = async () => {
    const recordData = {
      patient: patient.id,
      created_by: getProfessionalId() || 2,
      professional: getProfessionalId() || 2,
      metrics: metricObj,
      composition_body: antropometryObj.composition_body,
      antropometry: {
        neck: antropometryObj.neck,
        shoulder: antropometryObj.shoulder,
        breastPlate: antropometryObj.breastPlate,
        waist: antropometryObj.waist,
        abdomen: antropometryObj.abdomen,
        hip: antropometryObj.hip,
        right_calf: antropometryObj.right_calf,
        left_calf: antropometryObj.left_calf,
        right_thigh: antropometryObj.right_thigh,
        left_thigh: antropometryObj.left_thigh,
        right_proximal_thigh: antropometryObj.right_proximal_thigh,
        left_proximal_thigh: antropometryObj.left_proximal_thigh,
      },
      life_habits: anamneseObj.lifeHabits,
      pathology: anamneseObj.pathology,
      clinical_assessment: anamneseObj.clinicalAssessment,
      urinary_habits: anamneseObj.urinaryHabits,
      eating_habits: anamneseObj.eatingHabits,
      anamnese: {
        clinical_case: anamneseObj.clinical_case,
        data: anamneseObj.date,
      },
    };

    recordData.composition_body.type = bodyType;
    recordData.composition_body.protocol = selectedProtocol;
    recordData.life_habits.foodRestriction = foodRestrictionSelectedOption;
    recordData.pathology.types = selectedPathologyList;
    recordData.clinical_assessment.appetite = appetiteSelectedOption;
    recordData.clinical_assessment.chewing = selectedChewing;
    recordData.clinical_assessment.intestionalHabit =
      intestionalHabitSelectedOption;
    recordData.clinical_assessment.format = stoolShape;
    recordData.clinical_assessment.frequencyType = evacuationFrequency;
    recordData.clinical_assessment.stoolColor = shitColorOption;
    recordData.urinary_habits.urinaryHydratation = hydroSelectedOption;

    const id = toast.loading("Salvando prontuário..");
    await api
      .post("/historico-prontuario/", recordData)
      .then((resp) => {
        toast.update(id, {
          render: "Prontuário salvo com sucesso!",
          type: "success",
          autoClose: 5000,
          isLoading: false,
        });

        navigate("/medicalRecordHistory", {
          state: {
            patient: {
              id: patient.id
            }
          },
        });
      })
      .catch(() => {
        toast.update(id, {
          render: "Houve uma falha ao salvar o prontuário",
          type: "error",
          autoClose: 5000,
          isLoading: false,
        });
      });
  };

  const initializeFields = () => {
    if (pageType == "view" || pageType == "edit") {
      setMetricObj({
        height: recordData.metrics.height,
        weight: recordData.metrics.currentWeight,
        date: recordData.metrics.date,
      });
      setAntropometryObj({
        neck: recordData.antropometry.neck,
        abdomen: recordData.antropometry.abdomen,
        breastPlate: recordData.antropometry.breastPlate,
        hip: recordData.antropometry.hip,
        left_calf: recordData.antropometry.left_calf,
        left_proximal_thigh: recordData.antropometry.left_proximal_thigh,
        left_thigh: recordData.antropometry.left_thigh,
        right_calf: recordData.antropometry.right_calf,
        right_proximal_thigh: recordData.antropometry.right_proximal_thigh,
        right_thigh: recordData.antropometry.right_thigh,
        shoulder: recordData.antropometry.shoulder,
        waist: recordData.antropometry.waist,
        composition_body: {
          biceps: recordData.composition_body.biceps,
          abdominal: recordData.composition_body.abdominal,
          chest: recordData.composition_body.chest,
          medial_calf: recordData.composition_body.medial_calf,
          middle_axiliary: recordData.composition_body.middle_axiliary,
          subscapularis: recordData.composition_body.subscapularis,
          suprailiaca: recordData.composition_body.suprailiaca,
          thigh: recordData.composition_body.thigh,
          triceps: recordData.composition_body.triceps,
        },
      });

      setAnamneseObj({
        ideal_weight: 0,
        target_weight: 0,
        lifeHabits: {
          drinkAlcohol: recordData.life_habits.drinkAlcohol,
          smoking: recordData.life_habits.smoking,
          mealsAwayFromHome: recordData.life_habits.mealsAwayFromHome,
          mealDescription: recordData.life_habits.mealDescription,
          numberOfPeopleLives: recordData.life_habits.numberOfPeopleLives,
          whoDoesHouseholdShopping:
            recordData.life_habits.whoDoesHouseholdShopping,
          whereYouShop: recordData.life_habits.whereYouShop,
          kiloSaltUsedPerMonth: recordData.life_habits.kiloSaltUsedPerMonth,
          litersOilUsedPerMonth: recordData.life_habits.litersOilUsedPerMonth,
          howManyTimes: recordData.life_habits.howManyTimes,
          takeChildren: recordData.life_habits.takeChildren,
        },
        pathology: {
          otherPathologies: recordData.pathology.otherPathologies,
          medicines: recordData.pathology.medicines,
          exams: recordData.pathology.exams,
          familyHistory: recordData.pathology.familyHistory,
          observation: recordData.pathology.observation,
        },
        urinaryHabits: {
          describe: recordData.urinary_habits.describe,
          waterIntake: recordData.urinary_habits.waterIntake,
          observation: recordData.urinary_habits.observation,
        },
        clinicalAssessment: {
          evacuationFrequency:
            recordData.clinical_assessment.evacuationFrequency,
          useLaxative: recordData.clinical_assessment.useLaxative,
        },
        eatingHabits: {
          foodAllergy: recordData.eating_habits.foodAllergy,
          foodAversions: recordData.eating_habits.foodAversions,
          foodIntolerance: recordData.eating_habits.foodIntolerance,
          foodSuplements: recordData.eating_habits.foodSuplements,
          observation: recordData.eating_habits.observation,
        },
      });

      setSelectedSegmentList(recordData.metrics.segment);
      setBodyType(recordData.composition_body.type);
      setSelectedProtocol(recordData.composition_body.protocol);
      setSelectedPathologyList(recordData.pathology.types || []);
      setFoodRestrictionSelectedOption(recordData.life_habits.foodRestriction);
      setAppetiteSelectedOption(recordData.clinical_assessment.appetite);
      setSelectedChewing(recordData.clinical_assessment.chewing);
      setIntestionalHabitSelectedOption(
        recordData.clinical_assessment.intestionalHabit
      );
      setShitColorOption(recordData.clinical_assessment.stoolColor);
      setEvacuationFrequency(recordData.clinical_assessment.frequencyType);
      setStoolShape(recordData.clinical_assessment.format);
      setHydroSelectedOption(recordData.urinary_habits.urinaryHydratation);
    }
  };

  useEffect(() => {
    initializeFields();
  }, []);

  interface DadosPaciente {
    name?: string;
    nickname?: string;
    birthday?: string;
    sex: string;
    profilePicture?: string;
    email?: string;
    phone?: string;
    currentWeight?: number;
    habitualWeight?: number;
    weightDifference?: number;
  }


  useEffect(() => {
    const obtemPaciente = async () => {
      try {
        const response = await api.get(`/clients/${patient.id}`);
        const dados: DadosPaciente = response.data;

        const validSex = ["Masculino", "Feminino", "Outro"].includes(dados.sex) ? dados.sex : "S/N";

        setDadosPaciente({
          name: dados.name ?? "Nome não informado",
          nickname: dados.nickname ?? "",
          birthday: dados.birthday ?? "Data não informada",
          sex: validSex,
          profilePicture: dados.profilePicture ?? PatientProfile,
          email: dados.email ?? "Email não informado",
          phone: dados.phone ?? "Telefone não informado",
          currentWeight: dados.currentWeight ?? 0,
          habitualWeight: dados.habitualWeight ?? 0,
          weightDifference:
            dados.currentWeight && dados.habitualWeight
              ? dados.currentWeight - dados.habitualWeight
              : 0,
        });
      } catch (error) {
        console.error("Erro ao obter os dados do paciente:", error);
      }
    };

    obtemPaciente();
  }, []);

  const handleClick = React.useCallback(({ muscle }: IMuscleStats) => {
    setBodyData(() => [
      {
        name: '',
        muscles: [muscle],
      },
    ]);
  }, []);

  useEffect(() => {
    if (metricObj?.waist !== undefined && metricObj?.hip !== undefined && metricObj.waist > 0 && metricObj.hip > 0) {
      setMetricObj(prev => ({
        ...prev,
        rcq: Math.round((prev.waist! / prev.hip!) * 100) / 100,
      }));
    }
  }, [metricObj?.waist, metricObj?.hip]);

  useEffect(() => {
    if (metricObj?.height !== undefined && metricObj?.waist !== undefined && metricObj.height > 0 && metricObj.waist > 0) {
      setMetricObj(prev => ({
        ...prev,
        rce: Math.round((prev.waist! / prev.height!) * 100) / 100,
      }));
    }
  }, [metricObj?.waist, metricObj?.height]);

  useEffect(() => {
    const ageDetails = dadosPaciente?.birthday ? calculateAgeDetails(dadosPaciente.birthday) : null;
    const years = ageDetails ? ageDetails.years : null;

    let defaultValue1 = dadosPaciente?.sex === 'Masculino' ? 1.112 : 1.097;
    let defaultValue2 = dadosPaciente?.sex === 'Masculino' ? 0.00043499 : 0.00046971;
    let defaultValue3 = dadosPaciente?.sex === 'Masculino' ? 0.00000055 : 0.00000056;
    let defaultValue4 = dadosPaciente?.sex === 'Masculino' ? 0.0002882 : 0.00012828;

    if (
      metricObj?.abdomenDobras !== undefined && metricObj.abdomenDobras > 0 &&
      metricObj?.supraIliaca !== undefined && metricObj.supraIliaca > 0 &&
      metricObj?.axilarMedia !== undefined && metricObj.axilarMedia > 0 &&
      metricObj?.chestDobras !== undefined && metricObj.chestDobras > 0 &&
      metricObj?.thighDobras !== undefined && metricObj.thighDobras > 0 &&
      metricObj?.triceps !== undefined && metricObj.triceps > 0 &&
      metricObj?.subscapular !== undefined && metricObj.subscapular > 0
    ) {
      let soma =
        metricObj.abdomenDobras +
        metricObj.supraIliaca +
        metricObj.axilarMedia +
        metricObj.chestDobras +
        metricObj.thighDobras +
        metricObj.triceps +
        metricObj.subscapular;

        let dcValue = defaultValue1 - (defaultValue2 * soma) + (defaultValue3 * (soma ** 2)) - (defaultValue4 * (years ?? 0));
        let percentG = (4.95 / dcValue) - 4.5;
        
        setMetricObj(prev => ({
          ...prev,
          dc: Math.round(dcValue * 100) / 100,
          percentageG: Math.round(percentG * 100) / 100
        }));               
    }
  }, [metricObj.abdomenDobras, metricObj.supraIliaca, metricObj.axilarMedia, metricObj.chestDobras, metricObj.thighDobras, metricObj.triceps, metricObj.subscapular])

  return (
    <PageStructure>
      <S.Wrapper>
        <S.ContentWrapper>

          <PatientSummary {...dadosPaciente} />

          <S.MainTitleWrapper>
            <S.TextWrapper>
              <S.TextIconImage src={Kg} />
              <S.MainTitle>Métricas</S.MainTitle>
            </S.TextWrapper>
            <CustomButton
              theme="secondary"
              onClick={() => handleSaveRecord()}
              disabled={pageType === "view"}
            >
              <FaSave style={{ marginRight: "4px" }} /> Salvar
            </CustomButton>
          </S.MainTitleWrapper>
          <S.SectionsWrapper>
            <S.Subtitle>Dados Bioimpedância</S.Subtitle>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="date"
                label="Data"
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    date: formatDate(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                value={metricObj.date}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Altura"
                value={metricObj.height}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    height: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Peso"
                value={metricObj.weight?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    weight: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Massa Livre de Gordura"
                value={metricObj.fatFreeMass?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    fatFreeMass: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Massa Muscular Esquelética"
                value={metricObj.skeletalMuscleMass?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    skeletalMuscleMass: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
            </S.SeciontInputsWrapper>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="number"
                label="Massa de Gordura"
                value={metricObj.fatMass?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    fatMass: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Nível de Gordura Visceral"
                value={metricObj.visceralFatLevel?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    visceralFatLevel: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="PGC"
                value={metricObj.pgc?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    pgc: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Grau de Obesidade"
                value={metricObj.obesityGrade?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    obesityGrade: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="TMB (GEB)"
                value={metricObj.tmb?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    tmb: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
            </S.SeciontInputsWrapper>
          </S.SectionsWrapper>

          <S.SectionsWrapper>
            <S.Subtitle>Circunferências</S.Subtitle>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="number"
                label="Braço Direito Relaxado"
                value={metricObj.armRelaxedRight?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    armRelaxedRight: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Braço Direito Contraído"
                value={metricObj.armContractedRight?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    armContractedRight: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Tórax"
                value={metricObj.chest?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    chest: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Cintura"
                value={metricObj.waist?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    waist: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Abdômen"
                value={metricObj.abdomen?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    abdomen: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
            </S.SeciontInputsWrapper>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="number"
                label="Quadril"
                value={metricObj.hip?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    hip: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Coxa"
                value={metricObj.thigh?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    thigh: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Panturrilha"
                value={metricObj.calf?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    calf: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="RCQ"
                value={metricObj.rcq?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    rcq: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="RCE"
                value={metricObj.rce?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    rce: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
            </S.SeciontInputsWrapper>
          </S.SectionsWrapper>

          <S.SectionsWrapper>
            <S.Subtitle>Dobras</S.Subtitle>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="number"
                label="Abdômen"
                value={metricObj.abdomenDobras?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    abdomenDobras: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="SupraIlíaca"
                value={metricObj.supraIliaca?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    supraIliaca: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Axilar Média"
                value={metricObj.axilarMedia?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    axilarMedia: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Tórax"
                value={metricObj.chestDobras?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    chestDobras: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="Coxa"
                value={metricObj.thighDobras?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    thighDobras: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
            </S.SeciontInputsWrapper>
            <S.SeciontInputsWrapper>
              <CustomInput
                type="number"
                label="Tríceps"
                value={metricObj.triceps?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    triceps: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="SubEscapular"
                value={metricObj.subscapular?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    subscapular: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="DC"
                value={metricObj.dc?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    dc: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
              <CustomInput
                type="number"
                label="%G"
                value={metricObj.percentageG?.toString()}
                onChange={(value) => {
                  setMetricObj((prevState) => ({
                    ...prevState,
                    percentageG: Number(value.target.value),
                  }));
                }}
                inputStyle={{ height: 40 }}
                disabled={pageType === "view"}
              />
            </S.SeciontInputsWrapper>
          </S.SectionsWrapper>
        </S.ContentWrapper>

        <S.ContentWrapper>
          <S.MainTitleWrapper onClick={() => setAntropometriaExpandable(!antropometriaExpandable)}>
            <S.TextWrapper>
              <S.TextIconImage src={MetricIcon} />
              <S.MainTitle>Antropometria</S.MainTitle>
            </S.TextWrapper>
            <S.IconWrapper>
              {antropometriaExpandable ? (
                <FaAngleUp size={24} />
              ) : (
                <FaAngleDown size={24} />
              )}
            </S.IconWrapper>
          </S.MainTitleWrapper>
          <AnimatePresence>
            {antropometriaExpandable ? (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0 }}
                transition={{ duration: 0.5, ease: "easeOut" }}
                style={{ overflow: "hidden" }}
              >
                <div style={{ display: "flex", gap: "10px", alignItems: "flex-start" }}>
                  <Model
                    data={bodyData}
                    style={{
                      width: "15rem",
                      padding: "2rem",
                      flexShrink: 0,
                    }}
                    onClick={handleClick}
                  />
                  <div style={{ flex: 1, display: "flex", flexDirection: "column", gap: "10px" }}>
                    <S.SectionsWrapper>
                      <S.Subtitle>Tronco</S.Subtitle>
                      <S.SeciontInputsWrapper>
                        <CustomInput
                          type="number"
                          label="Pescoço (cm)"
                          value={antropometryObj.neck?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              neck: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'neck',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Ombro (cm)"
                          value={antropometryObj.shoulder?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              shoulder: Number(value.target.value),
                            }));
                          }}

                          onFocus={() => handleClick({
                            muscle: 'front-deltoids',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Peitoral (cm)"
                          value={antropometryObj.breastPlate?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              breastPlate: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'chest',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Cintura (cm)"
                          value={antropometryObj.waist?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              waist: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'obliques',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Abdomen (cm)"
                          value={antropometryObj.abdomen?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              abdomen: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'abs',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Quadril (cm)"
                          value={antropometryObj.hip?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              hip: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'abductors',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                      </S.SeciontInputsWrapper>
                    </S.SectionsWrapper>
                    <S.SectionsWrapper>
                      <S.Subtitle>Membros inferiores</S.Subtitle>
                      <S.SeciontInputsWrapper>
                        <CustomInput
                          type="number"
                          label="Panturrilha direita (cm)"
                          value={antropometryObj.right_calf?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              right_calf: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'calves',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Panturrilha esquerda (cm)"
                          value={antropometryObj.left_calf?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              left_calf: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'calves',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Coxa direita (cm)"
                          value={antropometryObj.right_thigh?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              right_thigh: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'quadriceps',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Coxa esquerda (cm)"
                          value={antropometryObj.left_thigh?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              left_thigh: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'quadriceps',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Coxa proximal direita (cm)"
                          value={antropometryObj.right_proximal_thigh?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              right_proximal_thigh: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'quadriceps',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Coxa proximal esquerda (cm)"
                          value={antropometryObj.left_proximal_thigh?.toString()}
                          onChange={(value) => {
                            setAntropometryObj(prevState => ({
                              ...prevState,
                              left_proximal_thigh: Number(value.target.value),
                            }));
                          }}
                          onFocus={() => handleClick({
                            muscle: 'quadriceps',
                            data: {
                              exercises: [],
                              frequency: 0,
                            },
                          })}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                      </S.SeciontInputsWrapper>
                      <S.ExpandableAntropometryWrapper>
                        <ExpandableSection
                          title="Composição corporal"
                          icon={Composition}
                          content={
                            <S.BodyCompositionWrapper>
                              <S.SeciontInputsWrapper>
                                <ButtonOptionList
                                  title="Tipo"
                                  setSelectedValue={setBodyType}
                                  selectedValue={bodyType}
                                  disabled={pageType === "view"}
                                  options={[
                                    {
                                      title: "Pregas Cutâneas",
                                      value: "pregascutaneas",
                                    },
                                    {
                                      title: "Bioimpedância",
                                      value: "bioimpedancia",
                                    },
                                  ]}
                                />
                                <S.ProtocolWrapper>
                                  <CustomDropdown
                                    label="Protocolo"
                                    options={["Teste"]}
                                    setSelectedOption={setSelectedProtocol}
                                    selectedOption={selectedProtocol}
                                  />
                                </S.ProtocolWrapper>
                              </S.SeciontInputsWrapper>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Biceps (mm)"
                                  value={antropometryObj.composition_body.biceps?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.biceps =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <CustomInput
                                  type="number"
                                  label="Abdominal (mm)"
                                  value={antropometryObj.composition_body.abdominal?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.abdominal =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <CustomInput
                                  type="number"
                                  label="Tríceps (mm)"
                                  value={antropometryObj.composition_body.triceps?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.triceps =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                              </S.SeciontInputsWrapper>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Suprailíaca (mm)"
                                  value={antropometryObj.composition_body.suprailiaca?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.suprailiaca =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <CustomInput
                                  type="number"
                                  label="Axilar Média (mm)"
                                  value={antropometryObj.composition_body.middle_axiliary?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.middle_axiliary =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <CustomInput
                                  type="number"
                                  label="Subescapular (mm)"
                                  value={antropometryObj.composition_body.subscapularis?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.subscapularis =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                              </S.SeciontInputsWrapper>
                              <S.SeciontInputsWrapper>
                                <CustomInput
                                  type="number"
                                  label="Tórax (mm)"
                                  value={antropometryObj.composition_body.chest?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.chest = Number(
                                      value.target.value
                                    );
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <CustomInput
                                  type="number"
                                  label="Coxa (mm)"
                                  value={antropometryObj.composition_body.thigh?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.thigh = Number(
                                      value.target.value
                                    );
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <CustomInput
                                  type="number"
                                  label="Panturrilha Medial (mm)"
                                  value={antropometryObj.composition_body.medial_calf?.toString()}
                                  onChange={(value) => {
                                    antropometryObj.composition_body.medial_calf =
                                      Number(value.target.value);
                                    setAntropometryObj(antropometryObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                              </S.SeciontInputsWrapper>
                            </S.BodyCompositionWrapper>
                          }
                        />
                      </S.ExpandableAntropometryWrapper>
                    </S.SectionsWrapper>
                  </div>
                </div>
              </motion.div>
            ) : (
              ""
            )}
          </AnimatePresence>
        </S.ContentWrapper>
        {!["estagiários", "estagiario", "estagiarios", "estagiário"].includes(
          userRole || ""
        ) && (
            <S.ContentWrapper>
              <S.MainTitleWrapper onClick={() => setAnamneseExpandable(!anamneseExpandable)}>
                <S.TextWrapper>
                  <S.TextIconImage src={CheckList} />
                  <S.MainTitle>Anamnese</S.MainTitle>
                </S.TextWrapper>
                <S.IconWrapper>
                  {anamneseExpandable ? (
                    <FaAngleUp size={24} />
                  ) : (
                    <FaAngleDown size={24} />
                  )}
                </S.IconWrapper>
              </S.MainTitleWrapper>
              <AnimatePresence>
                {anamneseExpandable ? (
                  <motion.div
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.5, ease: "easeOut" }}
                    style={{ overflow: "hidden" }}
                  >
                    <S.SectionsWrapper>
                      <S.SeciontInputsWrapper>
                        <CustomInput
                          label="Data"
                          type="date"
                          value={anamneseObj.date}
                          inputStyle={{ height: 40 }}
                          onChange={(value) => {
                            setAnamneseObj(prevState => ({
                              ...prevState,
                              date: formatDate(value.target.value),
                            }));
                          }}
                        />
                        <CustomInput
                          type="number"
                          label="Peso Ideal"
                          value={anamneseObj?.ideal_weight.toString()}
                          onChange={(value) => {
                            anamneseObj.ideal_weight =
                              Number(value.target.value);
                            setAnamneseObj(anamneseObj);
                          }}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                        <CustomInput
                          type="number"
                          label="Meta"
                          value={anamneseObj?.target_weight.toString()}
                          onChange={(value) => {
                            anamneseObj.target_weight =
                              Number(value.target.value);
                            setAnamneseObj(anamneseObj);
                          }}
                          inputStyle={{ height: 40 }}
                          disabled={pageType === "view"}
                        />
                      </S.SeciontInputsWrapper>
                      <S.ExpandableWrapper>
                        <ExpandableSection
                          title="Hábitos de vida"
                          icon={LifeStyle}
                          content={
                            <S.HabitsWrapper>
                              <ButtonOptionList
                                selectedValue={foodRestrictionSelectedOption}
                                setSelectedValue={
                                  setFoodRestrictionSelectedOption
                                }
                                title="Restrição alimentar"
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "Nenhum",
                                    value: "nenhum",
                                  },
                                  {
                                    title: "Vegetariano",
                                    value: "vegetariano",
                                  },
                                  {
                                    title: "Vegano",
                                    value: "vegano",
                                  },
                                ]}
                              />
                              <S.CheckboxListWrapper>
                                <S.CheckBoxWrapper>
                                  <S.InputRecord
                                    type="checkbox"
                                    checked={anamneseObj.lifeHabits.drinkAlcohol}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      anamneseObj.lifeHabits.drinkAlcohol =
                                        event.target.checked;
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.CheckBoxLabel>
                                    Ingere bebida alcoólica
                                  </S.CheckBoxLabel>
                                </S.CheckBoxWrapper>
                                <S.CheckBoxWrapper>
                                  <S.InputRecord
                                    type="checkbox"
                                    checked={anamneseObj.lifeHabits.smoking}
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      anamneseObj.lifeHabits.smoking =
                                        event.target.checked;
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.CheckBoxLabel>Fumante</S.CheckBoxLabel>
                                </S.CheckBoxWrapper>
                              </S.CheckboxListWrapper>
                              <S.AwayHomeWrapper>
                                <S.CheckBoxWrapper>
                                  <S.InputRecord
                                    type="checkbox"
                                    checked={
                                      anamneseObj.lifeHabits.mealsAwayFromHome
                                    }
                                    onChange={(
                                      event: React.ChangeEvent<HTMLInputElement>
                                    ) => {
                                      setHasMealsAwayFromHome(
                                        !hasMealsAwayFromHome
                                      );
                                      anamneseObj.lifeHabits.mealsAwayFromHome =
                                        event.target.checked;
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.CheckBoxLabel>
                                    Refeições fora de casa
                                  </S.CheckBoxLabel>
                                </S.CheckBoxWrapper>
                                <CustomInput
                                  type="string"
                                  placeholder="Quais"
                                  value={anamneseObj.lifeHabits.mealDescription}
                                  inputStyle={{ height: 40, width: "40%" }}
                                  onChange={(value) => {
                                    anamneseObj.lifeHabits.mealDescription =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={
                                    !hasMealsAwayFromHome || pageType === "view"
                                  }
                                />
                              </S.AwayHomeWrapper>
                              <S.SectionsWrapper>
                                <S.SectionTitle>Habitos de compra</S.SectionTitle>
                                <S.SeciontInputsWrapper>
                                  <CustomInput
                                    type="number"
                                    label="Mora com quantas pessoas?"
                                    value={anamneseObj.lifeHabits.numberOfPeopleLives?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.numberOfPeopleLives =
                                        Number(value.target.value);
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                  <CustomInput
                                    type="text"
                                    label="Quem realiza as compras da casa?"
                                    value={
                                      anamneseObj.lifeHabits
                                        .whoDoesHouseholdShopping
                                    }
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.whoDoesHouseholdShopping =
                                        value.target.value;
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                </S.SeciontInputsWrapper>
                                <S.SeciontInputsWrapper>
                                  <CustomInput
                                    type="text"
                                    label="Onde realiza as compras?"
                                    value={anamneseObj.lifeHabits.whereYouShop}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.whereYouShop =
                                        value.target.value;
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.PurcasheQuantityWrapper>
                                    <CustomInput
                                      type="number"
                                      label="Quantas vezes por mês?"
                                      value={anamneseObj.lifeHabits.howManyTimes?.toString()}
                                      onChange={(value) => {
                                        anamneseObj.lifeHabits.howManyTimes =
                                          Number(value.target.value);
                                        setAnamneseObj(anamneseObj);
                                      }}
                                      inputStyle={{ height: 40 }}
                                      disabled={pageType === "view"}
                                    />
                                    <S.CheckBoxWrapper>
                                      <S.InputRecord
                                        type="checkbox"
                                        checked={
                                          anamneseObj.lifeHabits.takeChildren
                                        }
                                        onChange={(
                                          event: React.ChangeEvent<HTMLInputElement>
                                        ) => {
                                          anamneseObj.lifeHabits.takeChildren =
                                            event.target.checked;
                                          setAnamneseObj(anamneseObj);
                                        }}
                                        disabled={pageType === "view"}
                                      />
                                      <S.CheckBoxLabel>
                                        Leva os filhos junto?
                                      </S.CheckBoxLabel>
                                    </S.CheckBoxWrapper>
                                  </S.PurcasheQuantityWrapper>
                                </S.SeciontInputsWrapper>
                                <S.SeciontInputsWrapper>
                                  <CustomInput
                                    type="number"
                                    label="Litros de óleo utilizado por mês"
                                    value={anamneseObj.lifeHabits.litersOilUsedPerMonth?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.litersOilUsedPerMonth =
                                        Number(value.target.value);
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                  <CustomInput
                                    type="number"
                                    label="Kg de sal utilizado por mês"
                                    value={anamneseObj.lifeHabits.kiloSaltUsedPerMonth?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.lifeHabits.kiloSaltUsedPerMonth =
                                        Number(value.target.value);
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                </S.SeciontInputsWrapper>
                              </S.SectionsWrapper>
                            </S.HabitsWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Patologias"
                          icon={Genoma}
                          content={
                            <S.PatologyMainWrapper>
                              <MultiOptionButtonList
                                listOptions={PatologyList}
                                setSelectedList={setSelectedPathologyList}
                                selectedList={selectedPathologyList}
                                disabled={pageType === "view"}
                              />
                              <S.PatologyTextAreaWrapper>
                                <CustomTextArea
                                  label="Outras patologias"
                                  value={anamneseObj.pathology.otherPathologies}
                                  onChange={(value) => {
                                    anamneseObj.pathology.otherPathologies =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Medicamentos"
                                  value={anamneseObj.pathology.medicines}
                                  onChange={(value) => {
                                    anamneseObj.pathology.medicines =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Exames"
                                  value={anamneseObj.pathology.exams}
                                  onChange={(value) => {
                                    anamneseObj.pathology.exams =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Histórico familiar"
                                  value={anamneseObj.pathology.familyHistory}
                                  onChange={(value) => {
                                    anamneseObj.pathology.familyHistory =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Observação"
                                  value={anamneseObj.pathology.observation}
                                  onChange={(value) => {
                                    anamneseObj.pathology.observation =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                              </S.PatologyTextAreaWrapper>
                            </S.PatologyMainWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Avaliação clínica"
                          icon={Avaliacao}
                          content={
                            <S.ClinicAvaliationWrapper>
                              <S.FoodOptionsListWrapper>
                                <ButtonOptionList
                                  title="Apetite"
                                  setSelectedValue={setAppetiteSelectedOption}
                                  selectedValue={appetiteSelectedOption}
                                  disabled={pageType === "view"}
                                  options={[
                                    {
                                      title: "Normal",
                                      value: "normal",
                                    },
                                    {
                                      title: "Aumentado",
                                      value: "aumentado",
                                    },
                                    {
                                      title: "Diminuido",
                                      value: "diminuido",
                                    },
                                  ]}
                                />

                                <ButtonOptionList
                                  selectedValue={selectedChewing}
                                  setSelectedValue={setSelectedChewing}
                                  disabled={pageType === "view"}
                                  title="Mastigação"
                                  options={[
                                    {
                                      title: "Normal",
                                      value: "normal",
                                    },
                                    {
                                      title: "Rápida",
                                      value: "rapida",
                                    },
                                    {
                                      title: "Lenta",
                                      value: "lenta",
                                    },
                                  ]}
                                />
                              </S.FoodOptionsListWrapper>
                              <ButtonOptionList
                                selectedValue={intestionalHabitSelectedOption}
                                setSelectedValue={
                                  setIntestionalHabitSelectedOption
                                }
                                title="Hábito intestinal"
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "Normal",
                                    value: "normal",
                                  },
                                  {
                                    title: "Constipante",
                                    value: "constipante",
                                  },
                                  {
                                    title: "Diarréico",
                                    value: "diarreico",
                                  },
                                  {
                                    title: "Variado",
                                    value: "variado",
                                  },
                                ]}
                              />
                              <S.EvacuationDataWrapper>
                                <S.EvacuationFrequencyWrapper>
                                  <S.WrapperFrequencyInput>
                                    <CustomInput
                                      label="Frequência de evacuação"
                                      type="number"
                                      value={anamneseObj.clinicalAssessment.evacuationFrequency?.toString()}
                                      onChange={(value) => {
                                        anamneseObj.clinicalAssessment.evacuationFrequency =
                                          Number(value.target.value);
                                        setAnamneseObj(anamneseObj);
                                      }}
                                      inputStyle={{ height: 40 }}
                                      disabled={pageType === "view"}
                                    />
                                    <CustomDropdown
                                      label="Formato"
                                      options={["Teste"]}
                                      selectedOption={stoolShape}
                                      setSelectedOption={setStoolShape}
                                      style={{ color: "black" }}
                                    />
                                  </S.WrapperFrequencyInput>
                                </S.EvacuationFrequencyWrapper>
                                <ButtonOptionList
                                  selectedValue={evacuationFrequency}
                                  setSelectedValue={setEvacuationFrequency}
                                  title=""
                                  disabled={pageType === "view"}
                                  options={[
                                    {
                                      title: "Por dia",
                                      value: "pordia",
                                    },
                                    {
                                      title: "Por semana",
                                      value: "porsemana",
                                    },
                                  ]}
                                />
                              </S.EvacuationDataWrapper>
                              <S.CheckBoxWrapper>
                                <S.InputRecord
                                  type="checkbox"
                                  checked={
                                    anamneseObj.clinicalAssessment.useLaxative
                                  }
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    anamneseObj.clinicalAssessment.useLaxative =
                                      event.target.checked;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                />
                                <S.CheckBoxLabel>
                                  Faz uso de laxante
                                </S.CheckBoxLabel>
                              </S.CheckBoxWrapper>
                              <ButtonOptionList
                                title="Cor das fezes"
                                setSelectedValue={setShitColorOption}
                                selectedValue={shitColorOption}
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "Marrom",
                                    value: "marrom",
                                  },
                                  {
                                    title: "Amarelo",
                                    value: "amarelo",
                                  },
                                  {
                                    title: "Verde",
                                    value: "verde",
                                  },
                                  {
                                    title: "Avermelhado",
                                    value: "avermelhado",
                                  },
                                  {
                                    title: "Escura",
                                    value: "escura",
                                  },
                                  {
                                    title: "Clara",
                                    value: "clara",
                                  },
                                ]}
                              />
                            </S.ClinicAvaliationWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Hábitos urinários"
                          icon={SistemaUrinario}
                          content={
                            <S.UrineExpandableWrapper>
                              <S.UrineStartInformationsWrapper>
                                <CustomInput
                                  type="text"
                                  label="Descreva"
                                  value={anamneseObj.urinaryHabits.describe}
                                  onChange={(value) => {
                                    anamneseObj.urinaryHabits.describe =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  inputStyle={{ height: 40 }}
                                  disabled={pageType === "view"}
                                />
                                <S.HydroInputWrapper>
                                  <CustomInput
                                    type="number"
                                    label="Ingestão hídrica"
                                    value={anamneseObj.urinaryHabits.waterIntake?.toString()}
                                    onChange={(value) => {
                                      anamneseObj.urinaryHabits.waterIntake =
                                        value.target.value;
                                      setAnamneseObj(anamneseObj);
                                    }}
                                    inputStyle={{ height: 40 }}
                                    disabled={pageType === "view"}
                                  />
                                  <S.DescriptionInputText>
                                    Em litros
                                  </S.DescriptionInputText>
                                </S.HydroInputWrapper>
                              </S.UrineStartInformationsWrapper>
                              <ButtonOptionList
                                title="Hidratação urinária"
                                setSelectedValue={setHydroSelectedOption}
                                selectedValue={hydroSelectedOption}
                                disabled={pageType === "view"}
                                options={[
                                  {
                                    title: "1 Bem hidratado",
                                    value: "1bemhidratado",
                                  },
                                  {
                                    title: "2 Bem hidratado",
                                    value: "2bemhidratado",
                                  },
                                  {
                                    title: "3 Bem hidratado",
                                    value: "3bemhidratado",
                                  },
                                  {
                                    title: "4 Mal hidratado",
                                    value: "4malhidratado",
                                  },
                                  {
                                    title: "5 Mal hidratado",
                                    value: "5malhidratado",
                                  },
                                  {
                                    title: "6 Mal hidratado",
                                    value: "6malhidratado",
                                  },
                                  {
                                    title: "7 Desidratado",
                                    value: "7desidratado",
                                  },
                                  {
                                    title: "8 Desidratado",
                                    value: "8desidratado",
                                  },
                                ]}
                              />
                              <CustomTextArea
                                label="Observação"
                                style={{ height: 55 }}
                                value={anamneseObj.urinaryHabits.observation}
                                onChange={(value) => {
                                  anamneseObj.urinaryHabits.observation =
                                    value.target.value;
                                  setAnamneseObj(anamneseObj);
                                }}
                                placeholder="Motivo do paciente, observações gerais, objetivos.."
                                disabled={pageType === "view"}
                              />
                            </S.UrineExpandableWrapper>
                          }
                        />
                        <ExpandableSection
                          title="Hábitos alimentares"
                          icon={Comida}
                          content={
                            <S.FoodsHabits>
                              <S.FoodHabitsInput>
                                <CustomTextArea
                                  label="Suplementos alimentares"
                                  value={anamneseObj.eatingHabits.foodSuplements}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodSuplements =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Alergia alimentar"
                                  value={anamneseObj.eatingHabits.foodAllergy}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodAllergy =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Intolerância alimentares"
                                  value={anamneseObj.eatingHabits.foodIntolerance}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodIntolerance =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Aversão alimentares"
                                  value={anamneseObj.eatingHabits.foodAversions}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.foodAversions =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                                <CustomTextArea
                                  label="Observação"
                                  value={anamneseObj.eatingHabits.observation}
                                  onChange={(value) => {
                                    anamneseObj.eatingHabits.observation =
                                      value.target.value;
                                    setAnamneseObj(anamneseObj);
                                  }}
                                  disabled={pageType === "view"}
                                />
                              </S.FoodHabitsInput>
                            </S.FoodsHabits>
                          }
                        />
                      </S.ExpandableWrapper>
                    </S.SectionsWrapper>
                    <S.SectionsWrapper>
                      <S.SeciontInputsWrapper>
                        <MultiOptionButtonList
                          listOptions={SegmentList}
                          title="Protocolo"
                          setSelectedList={setSelectedSegmentList}
                          selectedList={selectedSegmentList}
                          disabled={pageType === "view"}
                        />
                      </S.SeciontInputsWrapper>
                    </S.SectionsWrapper>
                    <S.SectionsWrapper>
                      <CustomTextArea
                        label="Caso clínico / Objetivo"
                        style={{ height: 200 }}
                        onChange={(value) => {
                          setAnamneseObj(prevState => ({
                            ...prevState,
                            clinical_case: value.target.value,
                          }));
                        }}
                        placeholder="Motivo do paciente, observações gerais, objetivos.."
                        disabled={pageType === "view"}
                      />
                    </S.SectionsWrapper>
                  </motion.div>
                ) : (
                  ""
                )}
              </AnimatePresence>
            </S.ContentWrapper>
          )}
      </S.Wrapper>
    </PageStructure>
  );
};
