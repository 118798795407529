import { useQuery } from "@tanstack/react-query";
import { differenceInDays, format } from "date-fns";
import { useEffect, useMemo, useState } from "react";
import { IoEyeOutline } from "react-icons/io5";
import { MdOutlineModeEdit } from "react-icons/md";
import { LifeLine } from "react-loading-indicators";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { animated, useSpring } from "react-spring";
import CustomButton from "../../components/CustomButton";
import LabelTags from "../../components/LabelTags";
import PageStructure from "../../components/PageStructure";
import { IconWrapper, customStyles } from "../../components/Shared";
import { HeaderPage } from "../../components/Shared/HeaderPage";
import CreateUser from "../../modals/Patient/Create";
import UpdatePatient from "../../modals/Patient/Update";
import api from "../../service/api";
import { formatPhone } from "../../utils/formatPhone";
import {
  WrapperIcons
} from "./style";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { FiChevronDown, FiChevronUp } from "react-icons/fi";

const options = [
  "Nome",
  "CPF",
  "Desde de",
  "Telefone",
  "Email",
  "Tag",
  "Com Pendências",
];

export const PatientsPage = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState<any>([]);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [filterValue, setFilterValue] = useState("Nome");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedDate, setSelectedDate] = useState<Date | null>();
  const [clientTags, setClientTags] = useState<any[]>([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [patient, setPatient] = useState({});
  const [sortConfig, setSortConfig] = useState<{ key: string, direction: "asc" | "desc" }>({ key: "name", direction: "asc" });

  const { data: patients, isLoading } = useQuery({
    queryKey: ['patients'], queryFn: async () => {
      const response = await api.get("/clients");
      return response.data;
    }
  });

  const { data: tags } = useQuery({
    queryKey: ['tags'],
    queryFn: async () => {
      const response = await api.get("/tags/");
      return response.data;
    },
    staleTime: 60000
  });

  useEffect(() => {
    if (patients) {
      setFilteredData(patients);
    }
    if (tags) {
      setClientTags(tags);
    }
  }, [patients, tags]);

  const handleFilter = () => {
    if (!patients) return;

    const lowerCaseQuery = searchQuery.toLowerCase();
    const filtered = patients.filter((patient: any) => {
      switch (filterValue) {
        case "Nome":
          return patient.name?.toLowerCase().includes(lowerCaseQuery);
        case "CPF":
          return patient.document?.includes(searchQuery);
        case "Desde de":
          if (!selectedDate) return true;
          const patientDate = new Date(patient.created_at);

          return (
            patientDate.getFullYear() === selectedDate.getFullYear() &&
            patientDate.getMonth() === selectedDate.getMonth() &&
            patientDate.getDate() === selectedDate.getDate() + 1
          );
        case "Telefone":
          return patient.phone?.replace("+55", "").includes(searchQuery);
        case "Email":
          return patient.email?.toLowerCase().includes(lowerCaseQuery);
        case "Tag":
          return patient.tag?.name?.toLowerCase().includes(lowerCaseQuery);
        case "Com Pendências":
          return patient.has_pendency;
        default:
          return true;
      }
    });
    setFilteredData(filtered);
  };

  useEffect(() => {
    handleFilter();
  }, [searchQuery, filterValue, patients, selectedDate]);

  const closeModal = () => {
    setIsOpen(false);
  };

  const closeEditModal = () => {
    setShowEditModal(false);
  };

  const fade = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalIsOpen ? 1 : 0 },
  });

  const editFade = useSpring({
    from: { opacity: 0 },
    to: { opacity: showEditModal ? 1 : 0 },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Nome",
        accessor: "name",
        sortable: true,
      },
      {
        Header: "CPF",
        accessor: "document",
        sortable: true,
      },
      {
        Header: "Paciente desde",
        accessor: "created_at",
        Cell: ({ value }: { value: string }) => (
          <span>{format(new Date(value), "dd/MM/yyyy HH:mm")}</span>
        ),
        sortable: true,
      },
      {
        Header: "Telefone",
        accessor: "phone",
        Cell: ({ value }: { value: string }) => (
          <span>
            {formatPhone(value.replace("+55", "")) || "Não informado"}
          </span>
        ),
        sortable: true,
      },
      {
        Header: "Email",
        accessor: "email",
        Cell: ({ value }: { value: string }) => (
          <span>{value || "Não informado"}</span>
        ),
        sortable: true,
      },
      {
        Header: "Tag",
        accessor: "tag",
        Cell: (data: any) => (
          <LabelTags
            info={data.value.name}
            color={data.value.color}
          />
        ),
        sortable: false,
      },
      {
        Header: "Ações",
        accessor: "id",
        Cell: (data: any) => (
          <WrapperIcons>
            <IconWrapper
              onClick={() => {
                navigate("details", {
                  state: { patient: {
                    id: data.value
                  }},
                });
              }}
            >
              <IoEyeOutline size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
            <IconWrapper
              onClick={() => {
                setShowEditModal(true);
                setPatient({
                  id: data.value
                });
              }}
            >
              <MdOutlineModeEdit size={20} color="var(--primary-icon-color)" />
            </IconWrapper>
          </WrapperIcons>
        ),
      },
    ],
    []
  );

  const handleSort = (columnKey: string) => {
    const direction = sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key: columnKey, direction });
    const sortedData = [...filteredData].sort((a, b) => {
      if (a[columnKey] < b[columnKey]) return sortConfig.direction === "asc" ? -1 : 1;
      if (a[columnKey] > b[columnKey]) return sortConfig.direction === "asc" ? 1 : -1;
      return 0;
    });
    setFilteredData(sortedData);
  };

  return (
    <PageStructure
      onSearchChange={(e) => setSearchQuery(e)}
      onSelectChange={(e) => setFilterValue(e)}
      options={options} selectOption={filterValue}
      startDate={selectedDate} setStartDate={setSelectedDate}
    >
      <Modal
        isOpen={showEditModal}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={editFade}>
          <UpdatePatient
            closeModal={closeEditModal}
            updatePatients={() => { }}
            patient={patient}
          />
        </animated.div>
      </Modal>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fade}>
          <CreateUser closeModal={closeModal} updateClients={() => { }} />
        </animated.div>
      </Modal>
      <HeaderPage
        title=""
        buttonRight={
          <CustomButton onClick={() => setIsOpen(true)} theme="addPrimary" style={{ marginBottom: "20px" }}>
            Registrar pacientes
          </CustomButton>
        }
      />

      {isLoading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LifeLine color="#418DFD" size="medium" text="" textColor="" />
        </div>
      ) : (
        <TableContainer component={Paper} style={{ maxHeight: '732px', overflowY: 'auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.Header}
                    style={{
                      backgroundColor: "#1270FC", // Cor de fundo azul
                      color: "#ffffff", // Cor do texto em branco
                      fontWeight: "bold", // Para deixar o texto em negrito
                      cursor: column.sortable ? "pointer" : "default", // Cursor de pointer para colunas ordenáveis
                      position: 'sticky', // Tornando o cabeçalho fixo
                      top: 0, // Posicionando o cabeçalho no topo
                      zIndex: 1, // Garantindo que o cabeçalho fique acima do corpo da tabela
                    }}
                    onClick={() => column.sortable && handleSort(column.accessor)} // Chama a função de ordenação ao clicar
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      {column.Header}
                      {column.sortable && (
                        sortConfig.key === column.accessor && sortConfig.direction === "asc" ? (
                          <FiChevronUp size={16} style={{ marginLeft: "8px" }} />
                        ) : sortConfig.key === column.accessor && sortConfig.direction === "desc" ? (
                          <FiChevronDown size={16} style={{ marginLeft: "8px" }} />
                        ) : null
                      )}
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredData.map((row: any, index: any) => (
                <TableRow key={index}>
                  {columns.map((column) => (
                    <TableCell key={column.Header}>
                      {column.Cell ? column.Cell({ value: row[column.accessor] }) : row[column.accessor]}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </PageStructure>
  );
};

export default PatientsPage;
