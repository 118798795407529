import React, { useEffect, useState } from "react";
import {
  HeaderWrapper,
  InputsContainer,
  ProfileContainer,
  UserImage,
  UserName,
  UserProfile,
  WelcomeText,
} from "./style";
import CustomSearch from "../CustomSearch";
import CustomDropDown from "../CustomDropDown";
import { useLocation } from "react-router-dom";
import ToggleButton from "./Components/ToggleButton/ToggleButton";
import CustomDatePicker from "../CustomDatePicker";
import CustomInput from "../../CustomInput";

interface HeaderProps {
  onSearchChange: (value: string) => void;
  onSelectChange: (value: string) => void;
  options: string[];
  selectOption?: string;
  onYesterdayClick: () => void;
  onTodayClick: () => void;
  startDate?: Date | null;
  setStartDate?: (date: Date | null) => void;
  endDate?: Date | null;
  setEndDate?: (date: Date | null) => void;
}

const Header: React.FC<HeaderProps> = ({
  onSearchChange,
  onSelectChange,
  options,
  selectOption,
  onYesterdayClick,
  onTodayClick,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) => {
  const location = useLocation();
  const [isYesterday, setIsYesterday] = useState(false);
  const [userName, setUserName] = useState<string>("Usuário");
  const [userPhoto, setUserPhoto] = useState<string>(require("../../../assets/user-profile.png"));

  useEffect(() => {
    const storedName = localStorage.getItem("userName");
    const storedPhoto = localStorage.getItem("userPhoto");

    if (storedName) setUserName(storedName);
    if (storedPhoto) setUserPhoto("https://dev.api.bridges.conjosa.com.br" + storedPhoto);
  }, []);

  const handleToggleClick = () => {
    if (isYesterday) {
      onTodayClick();
    } else {
      onYesterdayClick();
    }
    setIsYesterday(!isYesterday);
  };

  const renderInputsForToday = () => {
    if (location.pathname !== "/today") return renderInputsForAgenda();

    return (
      <>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
        <CustomDropDown
          onChange={(e) => onSelectChange(e.target.value)}
          options={options}
        />
        <ToggleButton isYesterday={isYesterday} onClick={handleToggleClick} />
      </>
    );
  };

  const renderInputsForAgenda = () => {
    if (location.pathname !== "/") return renderInputsForWallet();

    return (
      <>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
      </>
    );
  };

  const renderInputsForWallet = () => {
    if (location.pathname !== "/wallet") return renderInputsForProfessionalsDetails();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Carteira</h1>
        <CustomDatePicker
          placeholder="Data inicial"
          selectedDate={startDate}
          onDateChange={setStartDate}
        />
        <span>–</span>
        <CustomDatePicker
          placeholder="Data final"
          selectedDate={endDate}
          onDateChange={setEndDate}
        />
      </div>
    );
  };

  const renderInputsForProfessionalsDetails = () => {
    if (location.pathname !== "/professionals/details") return renderInputsForProfessionals();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Profissional</h1>
      </div>
    );
  };

  const renderInputsForProfessionals = () => {
    if (location.pathname !== "/professionals") return renderInputsForPatients();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Profissional</h1>
        <CustomSearch onChange={(e) => onSearchChange(e.target.value)} />
      </div>
    );
  };

  const renderInputsForPatients = () => {
    if (location.pathname !== "/patients") return renderInputsForPatientsDetails();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pacientes</h1>

        {
          selectOption == 'Desde de' ?
            <CustomDatePicker
              placeholder="Data final"
              selectedDate={startDate}
              onDateChange={setStartDate}
            />
            :
            <CustomSearch
              onChange={(e) => onSearchChange(e.target.value)}
            />
        }

        <div>
          <CustomDropDown
            onChange={(e) => onSelectChange(e.target.value)}
            options={options}
          />
        </div>
      </div>
    );
  };
  
  const renderInputsForPatientsDetails = () => {
    if (location.pathname !== "/patients/details") return renderInputsForMedicalRecord();

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Pacientes</h1>
      </div>
    );
  };

  const renderInputsForMedicalRecord = () => {
    if (location.pathname !== "/medicalRecord") return null;

    return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
        <h1 style={{ marginRight: '20px', fontSize: '26px', fontWeight: 'bold', color: "#4B4B4B" }}>Prontuário</h1>
      </div>
    );
  };

  return (
    <HeaderWrapper>
      <InputsContainer>{renderInputsForToday()}</InputsContainer>
      <ProfileContainer>
        <UserProfile>
          <UserName>
            {userName}
            <WelcomeText>Seja bem-vindo!</WelcomeText>
          </UserName>
        </UserProfile>
        <UserImage src={userPhoto} alt="Perfil" />
      </ProfileContainer>
    </HeaderWrapper>
  );
};

export default Header;
