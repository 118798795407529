import styled from "styled-components";

export const ProfileHeader = styled.div`
    width: 100%;
    display: flex;
    padding: 14px 27px;
    flex-direction: row; /* Alterado para alinhar os elementos horizontalmente */
    justify-content: space-between;
    align-items: flex-start; /* Ajusta alinhamento vertical */
    gap: 32px; /* Espaçamento entre os elementos */
`;


export const ProfileHeaderTop = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
`

export const ProfileImageWrapper = styled.img`
    border-radius: 50%;
    width: 186px;
    height: 186px;
    overflow: hidden;
    /* border: 2px solid white; */
    /* box-shadow: 0px 0px 4px 5px rgba(0,0,0,0.1);     */
    img {
        object-fit: cover;
    }
`
export const ProfileActionsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap; /* Permite que os itens quebrem para a próxima linha */
    gap: 12px;
    justify-content: space-between; /* Opcional: ajusta o espaçamento entre os itens */
    max-width: 280px; /* Define uma largura máxima para organizar melhor */
`;

export const ProfileInfoWrapper = styled.div`
    display: flex;
    justify-content: space-between;
`    

export const SeparatorBottom = styled.div`
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`