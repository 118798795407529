import Modal from "react-modal";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Home from "../../pages/Home";
import PatientsPage from "../../pages/Patients";
import ProfessionalsPage from "../../pages/Professionals";
import PatientProfile from "../../pages/Patients/Profile";
import ProfessionalProfile from "../../pages/Professionals/Profile";
import { Control } from "../../pages/Control";
import { Wallet } from "../../pages/Wallet";
import Today from "../../pages/Today";
import { Navigate } from "react-router-dom";
import PostConsultation from "../../pages/PostConsultation";
import PreConsultation from "../../pages/PreConsultation";
import { MedicalRecord } from "../../pages/MedicalRecord";
import { MedicalRecordHistory } from "../../pages/MedicalRecordHistory";
import { Overview } from "../../pages/Overview";
import { Financial } from "../../pages/Financial";
import { Operational } from "../../pages/Operational";

export const AdminRoutes = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/today" Component={Today} />
          <Route path="/patients" Component={PatientsPage} />
          <Route path="/patients/details" Component={PatientProfile} />
          <Route path="/professionals" Component={ProfessionalsPage} />
          <Route path="/postConsultation" Component={PostConsultation} />
          <Route path="/preConsultation" Component={PreConsultation} />
          <Route
            path="/professionals/details"
            Component={ProfessionalProfile}
          />
          <Route path="/control" Component={Control} />
          <Route path="/overview" Component={Overview} />
          <Route path="/financial" Component={Financial} />
          <Route path="/operational" Component={Operational} />

          <Route path="/wallet" Component={Wallet} />
          <Route path="/medicalRecord" Component={MedicalRecord} />
          <Route
            path="/medicalRecordHistory"
            Component={MedicalRecordHistory}
          />
          <Route path="*" element={<Navigate to="/today" />} />
        </Routes>
      </BrowserRouter>
    </>
  );
};
