import styled from "styled-components";

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E5E5;
`;

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`;

export const ButtonWrapper = styled.div`
    margin: 10px 0;
    display: flex;
    justify-content: flex-end;
`;


export const Actions = styled.div`
    display: flex;
    gap: 10px;
`;

export const ModalContent = styled.div`
    margin-top: -15px;
    background: #ffffff;
    padding: 24px;
    border-radius: 12px;
    width: 400px;
    max-width: 100%;
    text-align: center;

    h3 {
        font-size: 22px;
        font-weight: 600;
        margin-bottom: 16px;
        color: #333333;
    }

    p {
        font-size: 16px;
        color: #555555;
        margin-bottom: 24px;
    }

    div {
        display: flex;
        justify-content: space-between;
        gap: 12px;

        button {
            flex: 1;
            padding: 10px 16px;
            font-size: 16px;
            border-radius: 8px;
            cursor: pointer;
            transition: background-color 0.2s ease;

            &:first-of-type {
                background-color: #1270FC;
                color: #ffffff;
                border: none;

                &:hover {
                    background-color: #0F5AC8;
                }
            }

            &:last-of-type {
                background-color: #f1f1f1;
                color: #555555;
                border: none;

                &:hover {
                    background-color: #e0e0e0;
                }
            }
        }
    }
`;

export const StyledModalWrapper = styled.div`
    background: #ffffff;
    padding: 24px;
    border-radius: 12px;
    width: 400px;
    max-width: 100%;
    text-align: center;
`;

export const StyledModalTitle = styled.h3`
    margin-top: -5px;
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333333;
`;

export const StyledForm = styled.form`
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 24px;
`;

export const StyledInput = styled.input`
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    &:focus {
        border-color: #1270fc;
        outline: none;
    }
`;

export const StyledSelect = styled.select`
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    &:focus {
        border-color: #1270fc;
        outline: none;
    }
`;

export const StyledButtonWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 12px;
`;

export const StyledButtonPrimary = styled.button`
    flex: 1;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 8px;
    background-color: #1270fc;
    color: #ffffff;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: #0f5ac8;
    }
`;

export const StyledButtonSecondary = styled.button`
    flex: 1;
    padding: 10px 16px;
    font-size: 16px;
    border-radius: 8px;
    background-color: #f1f1f1;
    color: #555555;
    border: none;
    cursor: pointer;
    font-weight: 600;
    transition: background-color 0.2s ease;
    &:hover {
        background-color: #e0e0e0;
    }
`;
