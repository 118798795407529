export const validateCPF = (cpf:any) => {
    // Remove caracteres não numéricos
    cpf = cpf.replace(/\D/g, "");
  
    // Verifica se o CPF tem 11 dígitos ou é uma sequência de números iguais
    if (cpf.length !== 11 || /^(\d)\1+$/.test(cpf)) {
      return false;
    }
  
    let sum;
    let remainder;
  
    // Verifica o primeiro dígito verificador
    sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(cpf.charAt(i)) * (10 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(9))) {
      return false;
    }
  
    // Verifica o segundo dígito verificador
    sum = 0;
    for (let i = 0; i < 10; i++) {
      sum += parseInt(cpf.charAt(i)) * (11 - i);
    }
    remainder = (sum * 10) % 11;
    if (remainder === 10 || remainder === 11) remainder = 0;
    if (remainder !== parseInt(cpf.charAt(10))) {
      return false;
    }
  
    return true;
  };