import { ReactNode, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { IoCloseOutline } from "react-icons/io5";
import {
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
} from "../../modals/Event/Create/style";
import CustomButton from "../CustomButton";
import { ModalStepper, ModalWrapper, Separator, Step } from "./style";

interface ModalStepByStepProps {
  steps: { stepTitle: string; stepIcon?: ReactNode }[];
  size?: "default" | "large" | "small";
  onChangeStep: (step: StepsMapped) => void;
  onConclude: () => void;
  onUpdate?: () => void;
  children: React.ReactNode;
  defaultTab?: string;
  closeModal?: any;
  validateNextStep?: () => boolean;
  modalTitle?: string;
  isUpdateModal?: boolean;
  isLoadingRequest?: boolean;
}

interface StepsMapped {
  stepTitle: string;
  stepIcon?: ReactNode;
  isFinished: boolean;
}

const ModalStepByStep = ({
  steps,
  onChangeStep,
  children,
  closeModal,
  onConclude,
  onUpdate,
  modalTitle,
  isUpdateModal,
  validateNextStep,
  isLoadingRequest,
}: ModalStepByStepProps) => {
  const [stepsMapped, setStepsMapped] = useState([] as StepsMapped[]);
  const [stepNumber, setStepNumber] = useState(0);
  const [selectedTab, setSelectedTab] = useState({} as StepsMapped);

  useEffect(() => {
    const stepsMap = steps?.map((step) => {
      return {
        ...step,
        isFinished: false,
      };
    });

    setStepsMapped(stepsMap);
    setSelectedTab(stepsMap[0]);
  }, []);

  const handleTabClick = (tab: StepsMapped, index: number) => {
    if (index >= stepNumber)
      if (validateNextStep && !validateNextStep?.()) return;

    setSelectedTab(tab);
    onChangeStep(tab);
    setStepNumber(index);
    setSelectedTab(tab);
  };

  const previousStep = (nextStep: number) => {
    if (stepNumber <= 0) return;

    setStepNumber(nextStep);
    setSelectedTab(stepsMapped[nextStep]);
    onChangeStep(stepsMapped[nextStep]);
  };

  const nextStep = (nextStep: number) => {
    if (stepNumber >= steps.length - 1) return;

    if (validateNextStep && !validateNextStep?.()) return;

    setStepNumber(nextStep);
    stepsMapped[nextStep - 1].isFinished = true;
    setStepsMapped([...stepsMapped]);
    setSelectedTab(stepsMapped[nextStep]);
    onChangeStep(stepsMapped[nextStep]);
  };

  return (
    <div>
      <HeaderWrapper>
        <div
          style={{
            display: "flex",
            flex: 1,
            justifyContent: "center",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <HeaderTitle>
            {modalTitle == null ? "Preencha o formulário" : modalTitle}
          </HeaderTitle>
        </div>
        <IoCloseOutline
          size={22}
          onClick={closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <div style={{ display: "flex" }}>
        <ModalStepper size={"default"}>
          {stepsMapped.map((step, index) => {
            return (
              <>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-start",
                          height: "100%",
                          marginBottom: "3px",
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Step
                            tabActive={selectedTab == step}
                            isFinished={step.isFinished}
                            onClick={() => {
                              handleTabClick(step, index);
                            }}
                          >
                            {step.isFinished && (
                              <FaCheck color="white" size={20} />
                            )}
                            {!step.isFinished &&
                              (step?.stepIcon ? step.stepIcon : index + 1)}
                          </Step>
                          {index + 1 !== stepsMapped.length && (
                            <Separator isFinished={step.isFinished} />
                          )}
                        </div>
                        <div
                          style={{
                            marginLeft: "6px",
                            marginTop: "12px",
                            fontSize: "14px",
                            color:
                              selectedTab.stepTitle == step.stepTitle
                                ? "#1270FC"
                                : "#000",
                            fontWeight:
                              selectedTab.stepTitle == step.stepTitle
                                ? 500
                                : 400,
                          }}
                        >
                          {step.stepTitle}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </ModalStepper>
        <div
          style={{
            width: "1px",
            flex: 1,
            background: "#E5E5E5",
            marginRight: "15px",
            marginTop: "8px",
            borderRadius: "8px",
          }}
        ></div>
        <ModalWrapper size={"default"}>
          <div style={{ flex: 3 }}>{children}</div>
          <FooterWrapper type="space-between">
            <CustomButton
              onClick={() => previousStep(stepNumber - 1)}
              disabled={stepNumber <= 0}
              theme="arrowLeft"
            >
              Anterior
            </CustomButton>
            {stepNumber == steps.length - 1 && isUpdateModal == false && (
              <CustomButton
                onClick={() => {
                  onConclude();
                }}
                theme="register"
                disabled={isLoadingRequest}
              >
                Cadastrar
              </CustomButton>
            )}
            {isUpdateModal == true && (
              <CustomButton
                onClick={() => {
                  onUpdate!();
                }}
                theme="update"
              >
                Salvar
              </CustomButton>
            )}
            <CustomButton
              disabled={stepNumber >= steps.length - 1}
              style={{ display: stepNumber >= steps.length - 1 ? 'none' : 'inherit' }}
              theme="arrowRight"
              rightIcon
              onClick={() => {
                nextStep(stepNumber + 1);
              }}
            >
              Próximo
            </CustomButton>
          </FooterWrapper>
        </ModalWrapper>
      </div>
    </div>
  );
};

export default ModalStepByStep;
