import { useEffect, useState } from "react";
import { IoCloseOutline, IoCopyOutline } from "react-icons/io5";
import CustomDropdown from "../../../../components/CustomDropDown";
import CustomLabel from "../../../../components/CustomLabel";
import { Tag } from "../../../../components/Tags";
import Modal from "react-modal";
import {
  FlexWrapper,
  ContentWrapper,
  HeaderTitle,
  HeaderWrapper,
  InfoWrapper,
  SeparatorBottom,
  ModalContainer,
  ModalHeader,
  ModalMessage,
  ModalActionsWrapper,
  CloseButton,
  ConfirmButton,
} from "../../../Event/Create/style";
import {
  Container,
  DollarIcon,
  PaymentContainer,
  PaymentContent,
  PaymentHeaderTitle,
  PaymentInfo,
  PaymentInfoDate,
  PaymentLabel,
  PaymentLabelReceipt,
  PaymentLabelWrapper,
  PaymentList,
  PaymentWrapper,
} from "./style";
import { customStyles } from "../../../../components/Shared";
import { animated, useSpring } from "react-spring";
import api from "../../../../service/api";
import PaymentEntry from "../../../Event/PaymentEntry";
import { toast } from "react-toastify";
import CustomButton from "../../../../components/CustomButton";
import { jwtDecode } from "jwt-decode";

interface PaymentTabProps {
  closeModal: () => void;
  setAppointments?: any;
  event: any;
}

interface Payment {
  date: string;
  title: string;
  value: string;
  mode: {
    name: string;
  }
  type: string;
  obs: string;
  link_status: string;
  appointment: number;
  is_pre_payment: boolean;
  created_by: string;
}

interface AppointmentPayment {
  payments: Payment[];
  still: number;
  total_paid: number;
  value: number;
}

const PaymentTab = (props: PaymentTabProps) => {
  const [form, setForm] = useState<any>({});
  const [refresh, setRefresh] = useState(false);
  const [appointmentPayment, setAppointmentPayment] = useState(
    {} as AppointmentPayment
  );
  const [totalBalance, setTotalBalance] = useState<number>(0);
  const [modalPaymentEntryIsOpen, setModalPaymentEntryIsOpen] = useState(false);
  const [modalConsumeIsOpen, setModalConsumeIsOpen] = useState(false);

  useEffect(() => {
    getAppointmentPayments();

    const fetchData = async () => {
      try {
        const response = await api.get(`/credits/total-balance/?user=${props.event.extendedProps.patient.id}`);
        setTotalBalance(response.data.total_balance);

        if (response.data.total_balance > 0 && appointmentPayment.still > 0) {
          setModalConsumeIsOpen(true);

        }
      } catch (error) {
        console.error("Erro ao carregar os dados:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (refresh) {
      props.setAppointments?.([]);
      getAppointmentPayments();
      setRefresh(false);
    }
  }, [refresh]);

  const formatCurrency = (value: string) => {
    value = value.replace(/(\d)(\d{2})$/, "$1,$2");
    value = value.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    return value;
  };

  const getAppointmentPayments = async () => {
    const result = await api
      .get(`/appointments/${props.event.id}/payments/`)
      .finally(() => {
        setRefresh(false);
        setModalPaymentEntryIsOpen(false);
      });

    setAppointmentPayment(result.data);
  };

  const closePaymentEntryModal = () => {
    setModalPaymentEntryIsOpen(false);
  };

  const fadePaymentEntry = useSpring({
    from: { opacity: 0 },
    to: { opacity: modalPaymentEntryIsOpen ? 1 : 0 },
  });

  const handleInputChange = (key: any, value: any) => {
    setForm({ ...form, [key]: value });
  };

  const handleSelectedOption = (value: string) => {
    switch (value) {
      case "Lançar pagamento":
        setModalPaymentEntryIsOpen(true);
        break;
      case "Consumir Créditos":
        setModalConsumeIsOpen(true);
        break;
      default:
        break;
    }

    handleInputChange("total", value);
  };

  const handleDate = (date: string) => {
    const dateString = date;
    const formattedDateString = dateString.replace("T", " ");
    const dateToFormat = new Date(formattedDateString);
    const dataFormatted = dateToFormat.toLocaleDateString("pt-BR", {
      weekday: "long",
      day: "2-digit",
      month: "long",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    return dataFormatted;
  };

  const handlePaymentMode = (mode: string) => {
    switch (mode) {
      case "CASH":
        return "Dinheiro";
      case "CREDIT":
        return "Crédito";
      case "DEBIT":
        return "Débito";
      case "PIX":
        return "Pix";
      case "TRANSFER":
        return "Transferência";
      case "LINK":
        return "Link de Pagamento";
      case "CONSUME":
        return "Consumo de Crédito";
    }
  };

  const handleGenerateConsumeCredits = async () => {
    try {
      const loadingToast = toast.info("Processando o consumo de créditos...", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
      });
  
      const token = localStorage.getItem("bridges.token");
      if (token) {
        const tokenData = jwtDecode<any>(token);
  
        const amountToUse = Math.min(totalBalance, appointmentPayment.still);
  
        await api.post(`/credits/use/`, {
          amount: amountToUse,
          created_by: tokenData.user_id,
          used_in: props.event.id,
        });
  
        const formData = new FormData();
        formData.append('value', amountToUse.toString());
        formData.append('date', new Date().toISOString());
        formData.append('mode', "CONSUME");
        formData.append('appointment', Number(props.event.id).toString());
        formData.append('type', 'CONS');
        formData.append('obs', 'Consumo de créditos do cliente');
        formData.append('is_pre_payment', "false");
  
        await api.post("/payments/", formData);
  
        toast.update(loadingToast, {
          render: "Pagamento lançado com sucesso!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });
  
        setModalConsumeIsOpen(false);
  
        toast.success("Créditos consumidos com sucesso!", {
          position: toast.POSITION.TOP_CENTER,
          autoClose: 3000,
        });
      }
    } catch (error) {
      console.error("Erro ao consumir créditos:", error);
      toast.error("Erro ao processar o consumo de créditos. Tente novamente mais tarde.", {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
      });
    }
  };
  


  return (
    <>
      <Modal
        isOpen={modalPaymentEntryIsOpen}
        onRequestClose={closePaymentEntryModal}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <animated.div style={fadePaymentEntry}>
          <PaymentEntry
            closeModal={() => {
              setModalPaymentEntryIsOpen(false);
            }}
            initialValue={appointmentPayment.still}
            event={props.event}
            doRefresh={setRefresh}
          />
        </animated.div>
      </Modal>

      <Modal
        isOpen={modalConsumeIsOpen}
        onRequestClose={() => setModalConsumeIsOpen(false)}
        shouldCloseOnOverlayClick={false}
        style={customStyles}
      >
        <ModalContainer>
          {totalBalance > 0 ? (
            <>
              <ModalHeader>Consumo de Créditos</ModalHeader>
              <ModalMessage>
                O cliente possui um saldo de <strong>R$ {formatCurrency((totalBalance * 100 || "000").toString())}</strong>{" "}
                em créditos. Deseja aplicar esse valor como desconto no total a ser pago?
              </ModalMessage>

              <ModalActionsWrapper>
                <CustomButton theme="cancel" onClick={() => setModalConsumeIsOpen(false)}>Fechar</CustomButton>

                <CustomButton
                  theme="success"
                  onClick={() => {
                    handleGenerateConsumeCredits();
                  }}
                >
                  Confirmar
                </CustomButton>
              </ModalActionsWrapper>
            </>
          ) : (
            <>
              <ModalHeader>Consumo de Créditos</ModalHeader>
              <ModalMessage>
                O cliente não possui créditos suficientes para aplicar como desconto.
              </ModalMessage>
              <ModalActionsWrapper>
                <CustomButton
                  theme="success"
                  onClick={() => setModalConsumeIsOpen(false)}
                >
                  Confirmar
                </CustomButton>
              </ModalActionsWrapper>
            </>
          )}
        </ModalContainer>
      </Modal>

      <HeaderWrapper>
        <HeaderTitle>Pagamento</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={props.closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <InfoWrapper>
          <FlexWrapper>
            <Container>
              <InfoWrapper>
                <PaymentLabelWrapper>
                  <PaymentLabel>Valor pago</PaymentLabel>
                  <PaymentInfo bold color="#128D57">
                    R${" "}
                    {formatCurrency(
                      (appointmentPayment.total_paid * 100 || "000").toString()
                    )}
                  </PaymentInfo>
                </PaymentLabelWrapper>

                <PaymentLabelWrapper>
                  <PaymentLabel paid={appointmentPayment.still <= 0}>
                    Valor restante
                  </PaymentLabel>
                  <PaymentInfo
                    bold
                    color="#B76E00"
                    paid={appointmentPayment.still <= 0}
                  >
                    R${" "}
                    {formatCurrency(
                      (appointmentPayment.still * 100 || "000").toString()
                    )}
                  </PaymentInfo>
                </PaymentLabelWrapper>
              </InfoWrapper>
            </Container>
            <Container>
              <InfoWrapper>
                <CustomLabel
                  label={`Valor ${props.event?.extendedProps?.service?.name || "Total"
                    }`}
                  infoBold={true}
                  info={`R$ ${formatCurrency(
                    (appointmentPayment.value * 100 || "000").toString()
                  )}`}

                />
                <CustomLabel
                  label="Saldo do Cliente"
                  infoBold={true}
                  info={`R$ ${formatCurrency(
                    (totalBalance * 100 || "000").toString()
                  )}`}>

                </CustomLabel>
              </InfoWrapper>
            </Container>
            <Container>
              <InfoWrapper>
                <CustomDropdown
                  selectedOption={form?.total}
                  setSelectedOption={handleSelectedOption}
                  label="Ações"
                  options={[
                    "Lançar pagamento",
                    "Consumir Créditos",
                  ]}
                />
                <CustomLabel label="Status Pagamento">
                  <Tag
                    type={props.event?.extendedProps?.status?.name == "Pago"
                      ? "paid"
                      : "pending"}
                    text={props.event?.extendedProps?.status?.name == "Pago"
                      ? "Pago"
                      : "Em aberto"}
                  />
                </CustomLabel>
              </InfoWrapper>
            </Container>
          </FlexWrapper>
          <SeparatorBottom />
          <HeaderTitle>Pagamentos Realizados</HeaderTitle>
          <PaymentList>
            {appointmentPayment?.payments?.length ? (
              appointmentPayment?.payments?.map((payment, index) => {
                return (
                  <PaymentContainer>
                    <PaymentHeaderTitle>
                      {payment?.is_pre_payment
                        ? `Pré-pagamento #${index + 1}`
                        : `Pagamento #${index + 1}`}
                      {payment?.link_status === "PENDING" ? (
                        <DollarIcon type="pending"> $ </DollarIcon>
                      ) : payment?.is_pre_payment ? (
                        <DollarIcon type="prepaid"> $ </DollarIcon>
                      ) : (
                        <DollarIcon type="paid"> $ </DollarIcon>
                      )}
                    </PaymentHeaderTitle>
                    <SeparatorBottom />
                    <Container>
                      <PaymentContent>
                        <PaymentWrapper>
                          <PaymentLabelWrapper>
                            <PaymentLabel>Valor</PaymentLabel>
                            <PaymentInfo
                              bold
                              color={
                                payment?.is_pre_payment ? "#4DBCFF" : "#128D57"
                              }
                            >
                              R${" "}
                              {formatCurrency(
                                (Number(payment.value) * 100).toString()
                              )}
                            </PaymentInfo>
                          </PaymentLabelWrapper>

                          <PaymentLabelWrapper>
                            <PaymentLabel>Tipo</PaymentLabel>
                            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                              <span style={{ fontSize: "14px", color: "#555" }}>
                                {handlePaymentMode(payment.mode.name)}
                              </span>
                              {payment.mode.name === "LINK" && payment.obs && (
                                <IoCopyOutline
                                  size={18}
                                  style={{ cursor: "pointer", color: "#128D57" }}
                                  onClick={() => {
                                    navigator.clipboard.writeText(payment.obs);
                                    toast.success("Link copiado para a área de transferência!", {
                                      position: toast.POSITION.TOP_CENTER,
                                      autoClose: 3000,
                                    });
                                  }}
                                />
                              )}
                            </div>
                          </PaymentLabelWrapper>

                        </PaymentWrapper>
                        <PaymentWrapper>
                          <PaymentLabelWrapper>
                            <PaymentLabel>Data</PaymentLabel>
                            <PaymentInfoDate>
                              {handleDate(payment.date)}
                            </PaymentInfoDate>
                          </PaymentLabelWrapper>
                        </PaymentWrapper>

                        <PaymentWrapper>
                          <PaymentLabelReceipt>
                            <PaymentLabel>Recebido por:</PaymentLabel>
                            <PaymentInfoDate>
                              {payment.created_by}
                            </PaymentInfoDate>
                          </PaymentLabelReceipt>
                        </PaymentWrapper>

                      </PaymentContent>
                    </Container>
                  </PaymentContainer>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  alignItems: "center",
                  gap: "15px",
                }}
              >
                <DollarIcon
                  type="pending"
                  style={{ position: "static", width: "50px", height: "50px" }}
                >
                  {" "}
                  ${" "}
                </DollarIcon>
                Sem pagamentos até o momento.
              </div>
            )}
          </PaymentList>
        </InfoWrapper>
      </ContentWrapper>
    </>
  );
};

export default PaymentTab;
