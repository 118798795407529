import { MdLockOpen, MdLockOutline } from "react-icons/md";
import styled from "styled-components";

export const ConfigHoursWrapper = styled.div`
  display: flex;
  flex-direction: column;
  div:first-child {
    margin-right: 5px;
  }
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  padding: 10px;
  width: 40%;
`

export const HeaderTitle = styled.span`
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: space-evenly;
    padding: 0 0 3px 0;
    margin: 0 -10px;
    border-bottom: 1px solid #f3f3f3;
`

export const IconContainer = styled.div`
  cursor: pointer;
  display: inline-block;
`;

export const LockIcon = styled(MdLockOpen)`
  color: #00d34699;
`;

export const LockOutlineIcon = styled(MdLockOutline)`
  color: #ff000480;
`;

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`

export const ConfigHoursContainer = styled.div`
  max-height: 400px;
  overflow-y: scroll;
`

export const WeekendContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
  margin-bottom: 5px;
`

export const WeekContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  justify-content: center;
`

export const CustomInputWrapper = styled.div`
  display: flex;
  padding: 5px;
`