export const formatDecimalValues = (value: number) => {
    return Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }).format(value)
}

export const formatPercentage = (value: any) => {
  return value ? `${Number(value).toFixed(2)}%` : "0.00%";
};
