import Modal from "react-modal";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import { HandleRoutes } from "./router";
import { useEffect, useState } from "react";
import { customStyles } from "./components/Shared";
import SolicitacaoPermissao from "./components/SolicitacaoPermissao";
import api from "./service/api";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'

function App() {
  Modal.setAppElement("#root");
  const queryClient = new QueryClient()

  const [showModal, setShowModal] = useState(false);
  const check = async () => {
    const { Notification } = window;

    if ('Notification' in window) {
      const permission = Notification.permission;
      if (permission !== 'granted') {
        setShowModal(true);
      }
    }
  }

  const getNotifications = () => {
    const token = localStorage.getItem("bridges.token");
    if (token) {
      api.get("/notificacoes/").then((resp) => {
        const not = resp.data;
        not.filter((item: any) => !item.read).forEach(async (n: any) => {
          await new Notification(n.title, {
            body: n.message,
            icon: 'https://clinicabridges.com.br/favicon.ico'
          })

          api.patch(`/notificacoes/${n.id}/`, {
            read: true
          }).then((resp) => {
            console.log(resp.data);
          }).catch((error) => {
            console.log('erro ao marcar notificação como lida', error)
          })

        });
      }
      ).catch((error) => {
        console.log('erro ao buscar notificações', error)
      });
    }
  }

  useEffect(() => {
    check();

    const interval = setInterval(() => {
      getNotifications();
    }, 10000);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Modal
          isOpen={showModal}
          onRequestClose={() => setShowModal(false)}
          shouldCloseOnOverlayClick={false}
          style={customStyles}
        >
          <SolicitacaoPermissao onClose={() => setShowModal(false)} />
        </Modal>
        <ToastContainer position="top-center" />
        <HandleRoutes />
      </QueryClientProvider>
    </>
  );
}

export default App;
