import { IoCloseOutline } from "react-icons/io5";
import CustomInput from "../../components/CustomInput";
import {
  FooterWrapper,
  HeaderTitle,
  HeaderWrapper,
  ModalWrapper,
} from "../Event/Create/style";
import CustomButton from "../../components/CustomButton";
import CustomDropdown from "../../components/CustomDropDown";
import { useEffect, useRef, useState } from "react";
import { MdDriveFileRenameOutline } from "react-icons/md";
import { FaRegCalendarCheck } from "react-icons/fa";
import { AiOutlineDollarCircle } from "react-icons/ai";
import { ContentWrapper, InputWrapper } from "./style";
import CustomTextArea from "../../components/CustomTextArea";
import api from "../../service/api";
import formatReaisToFloat from "../../utils/formatReaisToFloat";
import formatCurrency from "../../utils/formatCurrencyBr";
import { toast } from "react-toastify";
import { MdOutlinePayment } from "react-icons/md";
import { MdOutlineQrCodeScanner } from "react-icons/md";
import { MdNumbers } from "react-icons/md";
import { MdOutlineDoneAll } from "react-icons/md";
import { jwtDecode } from "jwt-decode";
import { deburr } from "lodash";
import { HiOutlineUserCircle, HiOutlineUserGroup } from "react-icons/hi2";
import { getProfessionalId } from "../../service/token.service";
import { PaymentMethod } from "../Event/PaymentEntry";

interface CreateTransactionHistory {
  closeModal: () => void;
  updateClients: () => void;
  fetchFunction: () => void;
  fetchWeeklyFunction?: () => void;
  sortChart: string;
}

export const CreateTransactionHistory = ({
  closeModal,
  updateClients,
  fetchFunction,
  fetchWeeklyFunction,
  sortChart,
}: CreateTransactionHistory) => {
  const [loading, setLoading] = useState(false);
  const [transactionOption, setTransactionOption] = useState("IN");
  const [value, setValue] = useState("");
  const [title, setTitle] = useState("");
  const [date, setDate] = useState("");
  const [description, setDescription] = useState("");
  const [pixKey, setPixKey] = useState("");
  const [installmentsValue, setInstallmentsValue] = useState("1x");
  const [idStone, setIdStone] = useState("");

  const [prePaymentValue, setPrePaymentValue] = useState(false);
  const [isAdvanceCredit, setIsAdvanceCredit] = useState(false);

  const [formData, setFormData] = useState<{
    professional: any | null;
    patient?: any | null;
  }>({
    professional: null,
    patient: null,
  });

  const [clients, setClients] = useState<any[]>([]);
  const clientsBackup = useRef<any[]>([]);

  const [isRecurring, setIsRecurring] = useState(false);
  const [recurringFrequency, setRecurringFrequency] = useState("");
  const [recurringRepetitions, setRecurringRepetitions] = useState("");

  const [professionals, setProfessionals] = useState<any>(null);

  const [options, setOptions] = useState<PaymentMethod[]>([]);
  const [selectedOption, setSelectedOption] = useState<PaymentMethod>({
    id: 0,
    key: "NONE",
    name: "Nenhum",
    tax_percentage: 0,
    max_installments: 0,
  });

  const handleSearch = (value: string) => {
    const normalizedSearch = deburr(value).toLowerCase();
    const filteredClients = clientsBackup.current.filter((client: any) =>
      deburr(client.name).toLowerCase().includes(normalizedSearch)
    );
    setClients(filteredClients);
  };

  const handleDropdownSelected = (key: any, value: any) => {
    setFormData({ ...formData, [key]: value });
  };


  const handleCreateHistory = async () => {
    try {
      if (isAdvanceCredit && formData.patient === null) {
        toast.warning("Cliente a ser creditado antecipadamente não informado!");
        return;
      }

      if (isRecurring && (!recurringFrequency || !recurringRepetitions)) {
        toast.warning("Defina a periodicidade e a quantidade de repetições para a movimentação recorrente!");
        return;
      }

      if (transactionOption === "COM" && formData.professional === null) {
        toast.warning("Profissional a ser creditado comissão não informado!");
        return;
      }

      let descriptionConc = generateDescription();

      const transactions = createTransactions(descriptionConc);

      let toastLoading = toast.loading("Registrando movimentação...", {
        className: "toast-loading",
      });

      await processTransactions(transactions, toastLoading);

      formData.patient = null;
      closeModal();
      fetchFunction();
      if (sortChart === "semanal") fetchWeeklyFunction?.();
    } catch (error) {
      handleError(error);
    }
  };

  // Função para gerar descrição
  const generateDescription = (): string => {
    let descriptionConc = description;

    if (selectedOption.key === "CREDIT") {
      if (!idStone) throw new Error("ID do pagamento (Stone) não informado!");

      descriptionConc += `\nParcelado em: ${installmentsValue} `;
      descriptionConc += `Transação (Stone): ${idStone}`;
    }

    if (selectedOption.key === "PIX") {
      descriptionConc += `\nComprovante de Pagamento: ${pixKey}`;
    }

    return descriptionConc;
  };

  // Função para criar transações recorrentes
  const createTransactions = (obs: string) => {
    const baseDate = new Date(date);
    const transactions = [];

    for (let i = 0; i < (isRecurring ? parseInt(recurringRepetitions) : 1); i++) {
      const transactionDate = new Date(baseDate);

      switch (recurringFrequency) {
        case "Diária":
          transactionDate.setDate(baseDate.getDate() + i);
          break;
        case "Semanal":
          transactionDate.setDate(baseDate.getDate() + i * 7);
          break;
        case "Quinzenal":
          transactionDate.setDate(baseDate.getDate() + i * 15);
          break;
        case "Mensal":
          transactionDate.setMonth(baseDate.getMonth() + i);
          break;
      }

      // Verifica se a data é retroativa (antes de hoje)
      if (transactionDate < new Date()) {
        // Para datas retroativas, define hora como 00:00:00
        transactionDate.setHours(0, 0, 0, 0);
      } else {
        // Para datas atuais ou futuras, mantém a hora do sistema
        const currentTime = new Date();
        transactionDate.setHours(currentTime.getHours(), currentTime.getMinutes(), currentTime.getSeconds(), currentTime.getMilliseconds());
      }

      console.log(transactionDate.toISOString());

      transactions.push({
        title,
        value: formatReaisToFloat(value),
        date: transactionDate.toISOString(),
        mode: selectedOption.id,
        obs,
        type: transactionOption,
        is_pre_payment: false,
        is_advance_credit: false,
        is_recurring: isRecurring,
        commission_user: formData.professional?.id,
      });
    }

    return transactions;
  };


  // Função para processar as transações
  const processTransactions = async (transactions: any[], toastLoading: any) => {
    for (const transaction of transactions) {
      const res = await api.post("/payments/", transaction);

      if (isAdvanceCredit) {
        await registerAdvanceCredit(res.data.value, res.data.id);
      }
    }

    const successMessage = isRecurring
      ? "Movimentações recorrentes registradas com sucesso!"
      : "Movimentação registrada com sucesso!";

    toast.update(toastLoading, {
      render: successMessage,
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  };

  // Função para registrar crédito antecipado
  const registerAdvanceCredit = async (value: number, paymentId: number) => {
    const token = localStorage.getItem("bridges.token");
    if (!token) return;

    const tokenData = jwtDecode<any>(token);

    let toastLoading = toast.loading("Registrando crédito antecipado...", {
      className: "toast-loading",
    });

    await api.post("/credits/", {
      amount: value,
      payment: paymentId,
      user: formData.patient.id,
      created_by: tokenData.user_id,
    });

    toast.update(toastLoading, {
      render: "Crédito antecipado registrado com sucesso!",
      type: "success",
      isLoading: false,
      autoClose: 3000,
    });
  };

  // Função para tratar erros
  const handleError = (error: any) => {
    toast.update(toast.loading(""), {
      render: error.message || "Falha ao tentar registrar movimentação!",
      type: "error",
      isLoading: false,
      autoClose: 3000,
    });

    formData.patient = null;
    closeModal();
  };


  useEffect(() => {
    api.get("/clients/").then((response) => {
      clientsBackup.current = response.data;
      setClients(response.data);
    });

    fetchProfessionals();
  }, []);

  const fetchProfessionals = () => {
    const professionalId = getProfessionalId();
    if (professionalId) {
      api.get(`/professionals/${professionalId}/`).then((response) => {
        setProfessionals([response.data]);
      });
    } else {
      api.get(`/professionals/`).then((response) => {
        setProfessionals(response.data);
      });
    }
  };

  useEffect(() => {
    if (transactionOption === 'COM') {
      const filteredOption = options.filter(option => option.key === 'PIX');
      setSelectedOption(filteredOption[0]);
    }
  }, [transactionOption]);


  useEffect(() => {
    api
      .get("/paymentMethods")
      .then((response) => {
        const methods: PaymentMethod[] = response.data.map((method: any) => ({
          id: method.id,
          key: method.key, // Agora o campo 'name' foi renomeado para 'key'
          name: method.name, // Agora o campo 'display_name' foi renomeado para 'name'
          tax_percentage: method.tax_percentage,
          max_installments: method.max_installments, // Adicionado o novo campo
        }));
  
        const filteredMethods =
          transactionOption === "COM"
            ? methods.filter((method) =>
                ["PIX", "CASH"].includes(method.key)
              )
            : methods;
  
        setOptions(filteredMethods);
      })
      .catch(() => {
        toast.error("Erro ao carregar métodos de pagamento.");
      });
  }, [transactionOption]);
  
  return (
    <ModalWrapper size="large">
      <HeaderWrapper>
        <HeaderTitle>Registro de movimentação</HeaderTitle>
        <IoCloseOutline
          size={22}
          onClick={closeModal}
          color="#919EAB"
          cursor={"pointer"}
        />
      </HeaderWrapper>
      <ContentWrapper>
        <InputWrapper>
          <CustomInput
            disabled={loading}
            label="Titulo"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Digite o nome do titulo"
            leftIcon={
              <MdDriveFileRenameOutline
                size={20}
                color="var(--primary-icon-color)"
              />
            }
          />
          <CustomInput
            label="Data"
            name="start_date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            type="date"
            leftIcon={
              <FaRegCalendarCheck size={16} color="var(--primary-icon-color)" />
            }
          />
          <CustomInput
            disabled={loading}
            label="Valor"
            onChange={(e) => setValue(formatCurrency(e.target.value))}
            value={value}
            name="value"
            placeholder="R$ 0,00"
            leftIcon={
              <AiOutlineDollarCircle
                size={20}
                color="var(--primary-icon-color)"
              />
            }
          />
        </InputWrapper>
        <InputWrapper>
          <CustomDropdown
            label="Método de pagamento"
            options={options}
            selectedOption={selectedOption.name}
            setSelectedOption={(value) =>
              setSelectedOption(value)
            }
            leftIcon={<MdOutlinePayment size={16} color="var(--primary-icon-color)" />}
          />

          {selectedOption.key == "PIX" && (
            <CustomInput
              disabled={loading}
              label="Comprovante de Pagamento"
              onChange={(e) => setPixKey(e.target.value)}
              value={pixKey}
              name="value"
              placeholder="Digite o código"
              leftIcon={
                <MdOutlineQrCodeScanner
                  size={20}
                  color="var(--primary-icon-color)"
                />
              }
            />
          )}

          {selectedOption.key == "CREDIT" && (
            <CustomDropdown
              label="Quantidade de parcelas"
              options={[
                "1x",
                "2x",
              ]}
              selectedOption={installmentsValue}
              setSelectedOption={(value) => setInstallmentsValue(value)}
              leftIcon={
                <MdNumbers size={16} color="var(--primary-icon-color)" />
              }
            />
          )}
          <CustomDropdown
            label="Tipo de movimentação"
            options={["Entrada", "Pendencia", "Saída", "Comissão"]}
            selectedOption={
              transactionOption == "IN"
                ? "Entrada"
                : transactionOption == "PEND"
                  ? "Pendência"
                  : transactionOption == "OUT"
                    ? "Saída"
                    : "Comissão"
            }
            setSelectedOption={(value) =>
              setTransactionOption(
                value == "Entrada"
                  ? "IN"
                  : value == "Pendencia"
                    ? "PEND"
                    : value == "Saída"
                      ? "OUT"
                      : "COM"
              )
            }
            leftIcon={
              <MdOutlinePayment size={16} color="var(--primary-icon-color)" />
            }
          />

          {selectedOption.key != 'CASH' && selectedOption.key != 'PIX' && selectedOption.key != 'LINK' && <CustomInput
            label="ID do pagamento (Stone)"
            value={idStone}
            onChange={e => setIdStone(e.currentTarget.value)}
            placeholder="Digite o identificador"
            leftIcon={
              <MdOutlineDoneAll size={16} color="var(--primary-icon-color)" />
            }
          />}
        </InputWrapper>

        {transactionOption != 'COM' && (
          <InputWrapper>
            <CustomDropdown
              label="Selecione a opção de pagamento"
              options={["Pré Pagamento", "Crédito Antecipado", "Movimentação Recorrente"]}
              selectedOption={
                prePaymentValue ? "Pré Pagamento" :
                  isAdvanceCredit ? "Crédito Antecipado" :
                    isRecurring ? "Movimentação Recorrente" : ""
              }
              setSelectedOption={(value) => {
                setPrePaymentValue(value === "Pré Pagamento");
                setIsAdvanceCredit(value === "Crédito Antecipado");
                setIsRecurring(value === "Movimentação Recorrente");

                if (value !== "Pré Pagamento") setPrePaymentValue(false);
                if (value !== "Crédito Antecipado") setIsAdvanceCredit(false);
                if (value !== "Movimentação Recorrente") setIsRecurring(false);
              }}
              leftIcon={<MdOutlinePayment size={16} color="var(--primary-icon-color)" />}
            />
          </InputWrapper>
        )}

        {isAdvanceCredit && (
          <CustomDropdown
            searchInput
            label="Paciente"
            onSearchInputChange={(value: string) => handleSearch(value)}
            setSelectedOption={(value) => handleDropdownSelected("patient", value)}
            selectedOption={formData.patient && formData.patient.name}
            options={clients}
            leftIcon={<HiOutlineUserGroup size={16} color="var(--primary-icon-color)" />}
          />
        )}

        {isRecurring && (
          <>
            <InputWrapper>
              <CustomDropdown
                label="Periodicidade"
                options={["Diária", "Semanal", "Quinzenal", "Mensal"]}
                selectedOption={recurringFrequency}
                setSelectedOption={(value) => setRecurringFrequency(value)}
              />
              <CustomInput
                label="Quantidade de Repetições"
                type="number"
                value={recurringRepetitions}
                onChange={(e) => setRecurringRepetitions(e.target.value)}
                placeholder="Digite o número de repetições"
              />
            </InputWrapper>
          </>
        )}

        <InputWrapper>
          {transactionOption === "COM" && (
            <CustomDropdown
              label="Profissional"
              setSelectedOption={(value: any) => {
                handleDropdownSelected("professional", value);
              }}
              selectedOption={formData.professional && formData.professional.name}
              options={professionals}
              leftIcon={<HiOutlineUserCircle size={16} color="var(--primary-icon-color)" />}
            />
          )}
        </InputWrapper>

        <InputWrapper>
          <CustomTextArea
            label="Descrição"
            onChange={(e) => setDescription(e.target.value)}
            value={description}
            name="observation"
            rows={5}
            placeholder="Observações"
          />
        </InputWrapper>
      </ContentWrapper>
      <FooterWrapper type="space-between">
        <CustomButton onClick={closeModal} disabled={loading} theme="cancel">
          Cancelar
        </CustomButton>
        <CustomButton
          disabled={loading}
          theme="register"
          onClick={() => handleCreateHistory()}
        >
          Registrar
        </CustomButton>
      </FooterWrapper>
    </ModalWrapper>
  );
};
