import { ReactNode } from "react";
import { ContentWrapper, PageWrapper, RightContentWrapper } from "./style";
import { SideMenu } from "../v2/SideMenu";
import Header from "../v2/HeaderMenu"; // Importando o HeaderMenu

interface PageStructureProps {
  title?: string;
  children: ReactNode;
  rightContent?: ReactNode;
  hidesidebar?: boolean;
  overflow?: string;
  topMenu?: ReactNode;
  onSearchChange?: (value: string) => void;
  onSelectChange?: (value: string) => void;
  options?: string[];
  onTodayClick?: () => void;
  onYesterdayClick?: () => void;
  showHeader?: boolean;
  startDate?: Date | null;  // Novo atributo para a data de início
  setStartDate?: (date: Date | null) => void;  // Função para setar a data de início
  endDate?: Date | null;  // Novo atributo para a data final
  setEndDate?: (date: Date | null) => void;
  selectOption?: string;
}

export const PageStructure = ({
  title,
  children,
  rightContent,
  hidesidebar,
  overflow,
  topMenu,
  onSearchChange = () => { },
  onSelectChange = () => { },
  options = [],
  onTodayClick = () => { },
  onYesterdayClick = () => { },
  showHeader = true,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  selectOption,
}: PageStructureProps) => {
  return (
    <PageWrapper hidesidebar={hidesidebar}>
      {!hidesidebar && <SideMenu />}


      {showHeader && (
        <>
          <Header
            onSearchChange={onSearchChange}
            onSelectChange={onSelectChange}
            options={options}
            onTodayClick={onTodayClick}
            onYesterdayClick={onYesterdayClick}
            startDate={startDate}
            setStartDate={setStartDate}
            endDate={endDate}
            setEndDate={setEndDate}
            selectOption={selectOption}
          />
        </>
      )}

      <ContentWrapper hidesidebar={hidesidebar} style={{ overflow }}>
        {title && <h1>{title}</h1>}
        {children}
      </ContentWrapper>

      {rightContent && (
        <RightContentWrapper>{rightContent}</RightContentWrapper>
      )}
    </PageWrapper>
  );
};

export default PageStructure;
