import React from "react";
import styled from "styled-components";

type PatientSummaryProps = {
  name?: string;
  nickname?: string; // Apelido opcional
  birthday?: string; // Formato ISO (YYYY-MM-DD)
  sex?: String;
  profilePicture?: string; // URL da foto de perfil
  email?: string;
  phone?: string;
  currentWeight?: number; // Peso Atual
  habitualWeight?: number; // Peso Habitual
  weightDifference?: number; // Diferença de peso
};

export const calculateAgeDetails = (birthDate: string): { years: number } => {
  const birth = new Date(birthDate);
  const today = new Date();

  let years = today.getFullYear() - birth.getFullYear();

  return { years };
};

const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("pt-BR", { year: "numeric", month: "long", day: "numeric" });
};

const PatientSummary: React.FC<PatientSummaryProps> = ({
  name,
  nickname,
  birthday,
  sex,
  profilePicture,
  email,
  phone,
  currentWeight,
  habitualWeight,
  weightDifference,
}) => {
  const { years } = calculateAgeDetails(birthday ?? "Nao informado");
  const formattedBirthDate = formatDate(birthday ?? "Nao informado");
  const displayName = nickname ? `${nickname} | ${name}` : name;

  return (
    <Container>
      <ProfilePicture>
        {profilePicture ? (
          <img src={profilePicture} alt={`Foto de perfil de ${name}`} />
        ) : (
          <Placeholder>{name?.charAt(0).toUpperCase()}</Placeholder>
        )}
      </ProfilePicture>
      <Details>
        {/* Primeira linha */}
        <Row>
          <Info>
            <Label>Nome:</Label>
            <Value>{displayName}</Value>
          </Info>
          <Info>
            <Label>Data de Nascimento:</Label>
            <Value>{formattedBirthDate}</Value>
          </Info>
          <Info>
            <Label>Idade:</Label>
            <Value>{years} anos</Value>
          </Info>
          <Info>
            <Label>Sexo:</Label>
            <Value>{sex}</Value>
          </Info>
        </Row>
        {/* Segunda linha */}
        <Row>
          <Info>
            <Label>Email:</Label>
            <Value>{email}</Value>
          </Info>
          <Info>
            <Label>Telefone:</Label>
            <Value>{phone}</Value>
          </Info>
          <Info>
            <Label>Peso Atual:</Label>
            <Value>{currentWeight ? `${currentWeight} kg` : "Não informado"}</Value>
          </Info>
          <Info>
            <Label>Peso Habitual:</Label>
            <Value>{habitualWeight ? `${habitualWeight} kg` : "Não informado"}</Value>
          </Info>
          <Info>
            <Label>Diferença:</Label>
            <Value>{weightDifference ? `${weightDifference} kg` : "Não informado"}</Value>
          </Info>
        </Row>
      </Details>
    </Container>
  );
};

// Estilos Adicionados
const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
`;


// Styled Components
const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
  border-radius: 12px; /* Cantos mais suavizados */
  padding: 20px; /* Maior espaçamento interno para destacar o conteúdo */
  width: 100%;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-color: #ffffff; /* Fundo branco puro */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1), 0px 3px 6px rgba(0, 0, 0, 0.08); /* Sombra mais suave e natural */
`;

const ProfilePicture = styled.div`
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const Placeholder = styled.div`
  background-color: #ccc;
  color: #fff;
  width: 100%;
  height: 100%;
  font-size: 48px;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Details = styled.div`
  display: flex;
  gap: 40px; /* Aumentando o espaçamento entre as informações */
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  width: 160px; /* Ajustando a largura para que as informações não se estiquem muito */
`;

const Label = styled.span`
  font-size: 16px;
  color: #637381;
  font-weight: bold;
  text-align: left;
  width: 100%;
`;

const Value = styled.span`
  font-size: 16px;
  color: #333;
`;

export default PatientSummary;
