import { useLocation, useNavigate } from "react-router-dom";
import { HorizontalWrapper, BoxWrapper, Logowrapper, MenuOptionsWrapper, MenuOptionWrapper, SideMenuWrapper } from "./style";
import BridgesLogo from "../../../assets/bridgesLogo.png";

import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import AccountCircleSharpIcon from "@mui/icons-material/AccountCircleSharp";
import ReplayRoundedIcon from "@mui/icons-material/ReplayRounded";
import AssignmentTurnedInRoundedIcon from "@mui/icons-material/AssignmentTurnedInRounded";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import SettingsIcon from "@mui/icons-material/Settings";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import LogoutIcon from "@mui/icons-material/Logout";
import cardMenu from "../../../assets/card-side-menu.png";
import { jwtDecode } from 'jwt-decode';
import { useEffect, useState } from "react";
import { FaCaretDown } from "react-icons/fa";

const menuOptions = [
    {
        icon: <CalendarViewMonthIcon />,
        text: "Consultas do dia",
        path: "/today",
        roles: ["gestor", "profissional", "recepcionista", "atendimento"],
    },
    {
        icon: <CalendarMonthIcon />,
        text: "Agenda",
        path: "/",
        roles: ["gestor", "profissional", "financeiro", "atendimento"],
    },
    {
        icon: <AccountBalanceWalletOutlinedIcon />,
        text: "Carteira",
        path: "/wallet",
        roles: ["gestor", "recepcionista", "financeiro"],
    },
    {
        icon: <PeopleOutlinedIcon />,
        text: "Profissionais",
        path: "/professionals",
        roles: ["gestor"],
    },
    {
        icon: <AccountCircleSharpIcon />,
        text: "Pacientes",
        path: "/patients",
        roles: ["gestor", "profissional", "atendimento"],
    },
    {
        icon: <ReplayRoundedIcon />,
        text: "Pré Consultas",
        path: "/preConsultation",
        roles: ["gestor", "atendimento"],
    },
    {
        icon: <AssignmentTurnedInRoundedIcon />,
        text: "Pós Consultas",
        path: "/postConsultation",
        roles: ["gestor", "atendimento"],
    },
    {
        icon: <AssessmentOutlinedIcon />,
        text: "Relatórios",
        path: "/control",
        roles: ["gestor"],
        subItems: [
            { icon: <AssessmentOutlinedIcon />, text: "Legado", path: "/control" },
            { icon: <CalendarViewMonthIcon />, text: "Visão Geral", path: "/overview" },
            { icon: <AccountBalanceWalletOutlinedIcon />, text: "Financeiro", path: "/financial" },
            { icon: <PeopleOutlinedIcon />, text: "Operacional", path: "/operational" },
        ],
    },
];

const configureItems = [
    { icon: <SettingsIcon />, text: "Configurações", path: "/settings" },
    { icon: <HelpOutlineIcon />, text: "Ajuda e Suporte", path: "/support" },
];

const logout = () => {
    localStorage.removeItem("bridges.token");
    window.location.href = "/login";
};

export const SideMenu = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userRole, setUserRole] = useState<string>("");
    const [expandedMenu, setExpandedMenu] = useState<string | null>(null);

    useEffect(() => {
        const token = localStorage.getItem("bridges.token");
        if (token) {
            try {
                const tokenData = jwtDecode<any>(token);
                setUserRole(tokenData.groups?.[0] || "");
            } catch (error) {
                console.error("Erro ao decodificar token:", error);
            }
        }
    }, []);

    const filteredMenuOptions = menuOptions.filter((option) => option.roles.includes(userRole));

    const isActive = (path: string) => location.pathname === path || (expandedMenu === "Relatórios" && path.includes("/control"));

    const isSubItemActive = (subItems: any[]) => {
        return subItems.some((subItem) => location.pathname === subItem.path);
    };

    const toggleMenu = (menuText: string, path: string) => {
        setExpandedMenu(menuText);
        navigate(path);
    };

    return (
        <SideMenuWrapper>
            <Logowrapper>
                <img src={BridgesLogo} alt="Bridges Logo" />
            </Logowrapper>

            <MenuOptionsWrapper style={{ overflowY: "auto", maxHeight: "calc(100vh)" }}>
                {filteredMenuOptions.map((option, index) => (
                    <div key={index} style={{ width: "100%" }}>
                        <MenuOptionWrapper
                            active={isActive(option.path) || (option.subItems && isSubItemActive(option.subItems))}
                            onClick={() => (option.subItems ? toggleMenu(option.text, option.path) : navigate(option.path))}
                        >
                            {option.icon}
                            <span>{option.text}</span>
                            {option.subItems && (
                                <FaCaretDown style={{ marginLeft: 10 }} />
                            )}
                        </MenuOptionWrapper>
                        {option.subItems && (expandedMenu === option.text || isSubItemActive(option.subItems)) && (
                            <MenuOptionsWrapper>
                                {option.subItems.map((subItem, subIndex) => (
                                    <MenuOptionWrapper
                                        key={subIndex}
                                        active={isActive(subItem.path)}
                                        subMenu={true}
                                        onClick={() => navigate(subItem.path)}
                                    >
                                        {subItem.icon}
                                        <span>{subItem.text}</span>
                                    </MenuOptionWrapper>
                                ))}
                            </MenuOptionsWrapper>
                        )}
                    </div>
                ))}

                <HorizontalWrapper />

                <MenuOptionsWrapper>
                    {configureItems.map((option, index) => (
                        <MenuOptionWrapper
                            key={index}
                            active={isActive(option.path)}
                            onClick={() => navigate(option.path)}
                        >
                            {option.icon}
                            <span>{option.text}</span>
                        </MenuOptionWrapper>
                    ))}
                </MenuOptionsWrapper>

                <BoxWrapper>
                    <img src={cardMenu} alt="Login" />
                </BoxWrapper>

                <MenuOptionWrapper theme="cancel" onClick={logout}>
                    <LogoutIcon />
                    <span>Sair</span>
                </MenuOptionWrapper>
            </MenuOptionsWrapper>
        </SideMenuWrapper>
    );
};
