import styled from "styled-components";

interface ModalWrapperProps {
    size?: 'default' | 'large' | 'small';
}

export const ModalWrapper = styled.div<ModalWrapperProps>`
    width: ${props => {
        switch (props.size) {
            case 'default':
                return '900px';
            case 'small':
                return '460px';
            default:
                return '1000px';
        }
    }};
    transition: all 0.3s ease-in-out;
`

export const HeaderWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    border-bottom: 1px solid #E5E5E5;
`

export const HeaderTitle = styled.span`
    font-size: 20px;
    font-weight: 600;
`

export const ContentWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-height: 500px;
    overflow-y: auto;
    gap: 16px;
    width: 100%;
    padding: 24px 0;
`

export const InfoWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 5;
`
export const AddressWrapper = styled.div`
    display: flex;
    gap: 24px;
`

export const ExamsWrapper = styled.div`
    display: flex;
    gap: 24px;
    justify-content: space-between;
`

export const ExamsSelectedWrapper = styled.div`
    display: flex;
    gap: 14px;
`

export const Separator = styled.div`
    border-right: 1px solid #E5E5E5;
`

export const AppointmentWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`

export const AppointmentWrapperColumn = styled.div`
    display: flex;
    gap: 16px;
    flex-direction: column;
    justify-content: space-between;
`

export const SeparatorBottom = styled.div`
    border-bottom: 1px solid #E5E5E5;
`

export const AttachmentWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 2;
`

export const AttachmentHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const AttachmentTitle = styled.span`
    font-size: 16px;
    font-weight: 600;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`
export const AttachmentList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    border-radius: 8px;
    background-color: #DFE3E8;
`

export const EmptyAttachment = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 8px;
    align-items: center;
    height: 100%;
    border-radius: 8px;
    background-color: #DFE3E8;
`

export const EmptyAttachamentText = styled.span`
    color: var(--Text-500, #919EAB);
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px; 
`

export const ObservationsWrapper = styled.div`

`

export const FlexWrapper = styled.div`
    display: flex;
    gap: 16px;
    justify-content: space-between;
`

export const ButtonFreeHoursWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex; 
    align-items: flex-end;
`

interface FooterWrapperProps {
    type?: 'space-between' | 'space-around' | 'space-evenly' | 'flex-end' | 'flex-start' | 'center';
}

export const FooterWrapper = styled.div<FooterWrapperProps>`
    padding-top: 10px;
    display: flex;
    justify-content: ${props => props.type ? props.type : 'space-between'};
`


// Estilos do Modal
export const ModalContainer = styled.div`
  padding: 20px;
  font-family: Arial, sans-serif;
  color: #333;
  max-width: 600px;
`;

export const ModalHeader = styled.h2`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 20px;
`;

export const ModalMessage = styled.p`
  margin-bottom: 20px;
  font-size: 16px;
`;

export const ModalActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

export const CloseButton = styled.button`
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  color: #555;
  font-weight: bold;
  &:hover {
    background-color: #e0e0e0;
  }
`;

export const ConfirmButton = styled.button`
  background-color: #128d57;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
  &:hover {
    background-color: #107c49;
  }
`;
