import styled from 'styled-components';

interface ButtonChartProps {
  isChecked: boolean;
}

export const ValuesContent = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;  /* Reduzido para não sobrecarregar a tela */
`;

export const SideHistoryContent = styled.div`
  margin-left: 15px;  /* Reduzido para não ficar muito afastado */
  padding-right: 8px;  /* Ajustado o padding */
  flex: 1;
  overflow-x: auto;  /* Permite rolagem horizontal, se necessário */
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: row;
  background-color: #F6F6F6;
  height: 100vh;
  overflow: hidden;
  flex-wrap: wrap;  /* Permite que os itens se ajustem em telas menores */
`;

export const MovimentListWrapper = styled.div`
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  height: auto;
  overflow-y: auto;
  gap: 8px;
  padding-right: 8px;
  max-height: calc(100vh - 400px);  /* Ajustado para a altura da tela de notebook */
`;

export const DateTitle = styled.div`
  text-align: center;
  color: #919EAB;
  font-size: 12px;  /* Ajustado o tamanho da fonte */
  margin-bottom: 4px;
`;

export const Button = styled.div`
  background-color: #1270FC;
  color: #FFFFFF;
  font-size: 11px;  /* Reduzido para adaptar ao notebook */
  height: 40px;
  width: 160px;  /* Reduzido para uma largura mais compacta */
  padding: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  margin-right: 4px;
  cursor: pointer;
  gap: 4px;
  transition: background 0.4s;
  &:hover {
    background: #47a7f5 radial-gradient(circle, transparent 1%, #47a7f5 1%) center/15000%;
  }
  &:active {
    background-color: #6eb9f7;
    background-size: 100%;
    transition: background 0s;
  }
`;

export const SubTitleInfosWrapper = styled.div`
  padding: 8px 0;
  font-size: 12px;  /* Ajustado para texto menor */
  color: #6c757d;  /* Cor neutra para o texto */
`;

export const SubHeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;  /* Reduzido o espaçamento */
`;

export const TextButton = styled.div`
  font-weight: 600;
  font-size: 11px;  /* Ajustado para tamanho menor */
`;

export const IconButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DataWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DisplayWrapper = styled.div`
  display: grid;
  grid-template-columns: 1.8fr 1.2fr;  /* Reduzido para uma proporção mais equilibrada */
  gap: 15px;  /* Reduzido o gap */
  width: 100%;
  margin-top: 15px;  /* Ajustado para menor espaçamento */

  /* Responsividade */
  @media (max-width: 1200px) {
    grid-template-columns: 1fr;  /* Para telas menores, a grid vira uma coluna única */
  }
`;

export const ChartWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;
  max-height: 50vh;  /* Limita a altura do card */
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 6px 8px -5px rgba(0, 0, 0, 0.18);

  /* Responsividade */
  @media (max-width: 1200px) {
    padding: 15px;
  }
`;

export const SidebarWrapper = styled.div`
  background-color: #ffffff;
  padding: 20px;  /* Reduzido padding */
  border-radius: 15px;  /* Ajustado bordas */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 6px 8px -5px rgba(0, 0, 0, 0.18);
  overflow-y: auto;
  max-height: 50vh;

  &::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
    border: none;
  }

  scrollbar-width: thin;
  scrollbar-color: transparent transparent;

  /* Responsividade */
  @media (max-width: 1200px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const ButtonChart = styled.div<ButtonChartProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(props) => props.isChecked ? "#1270FC" : ""};
  color: ${(props) => props.isChecked ? "#FFF" : ""};
  height: 100%;
  cursor: pointer;
  flex: 1;
  padding: 8px;  /* Ajuste no padding */
`;

export const ButtonChartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  border: 1px solid #919EAB52;
  height: 28px;  /* Ajuste na altura */
`;

export const ScrollableHistory = styled.div`
  overflow: auto;
`;

export const DataHeaderWrapper = styled.div`
  gap: 8px;
  display: flex;
  flex-direction: column;
  flex: 2;
`;

export const HeaderData = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  gap: 15px;  /* Reduzido o espaçamento */
`;

export const LeftContentHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex: 2;
  margin-right: 30px;  /* Ajuste no espaço à direita */
  align-items: center;
`;

export const RightContentHeader = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const customStyles = {
  overlay: {
    zIndex: 9000,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',  /* Semi-transparente para overlay */
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 16,
    padding: '15px',  /* Ajuste para o padding */
    maxWidth: '80%',  /* Garantir que o modal não ocupe toda a largura da tela */
    maxHeight: '80%',  /* Limite para a altura do modal */
    overflow: 'auto',  /* Permite rolagem se necessário */
    transition: 'all 0.3s ease',
  },
};


export const SidebarFilters = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 2px 6px 8px -5px rgba(0, 0, 0, 0.18);
  display: flex;
  flex-direction: column;
  gap: 15px;
  overflow-y: auto;
  max-height: 100vh;

  @media (max-width: 1200px) {
    padding: 15px;
  }

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

export const CustomInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  
  label {
    color: #6c757d;
    font-weight: 600;
  }

  input {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: border 0.3s ease;
    
    &:focus {
      border-color: #1270FC;
      outline: none;
    }
  }

  @media (max-width: 768px) {
    input {
      font-size: 12px;
    }
  }
`;

export const CustomDropdownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;

  label {
    color: #6c757d;
    font-weight: 600;
  }

  select {
    padding: 8px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: border 0.3s ease;
    
    &:focus {
      border-color: #1270FC;
      outline: none;
    }
  }

  @media (max-width: 768px) {
    select {
      font-size: 12px;
    }
  }
`;
