import styled from "styled-components";

export const Content = styled.div`
  background-color: #fff;
  padding: 20px; /* Reduzido para telas menores */
  width: 100%;
  height: 180px; /* Ajuste de altura para melhor visibilidade em telas pequenas */
  border-radius: 16px; /* Menor borda para uma aparência mais compacta */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 2px 8px 10px -5px rgba(0, 0, 0, 0.18);
  overflow: hidden; /* Evita que o conteúdo ultrapasse os limites do card */
`;

export const HeaderContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Ajuste para aumentar a proximidade entre os elementos */
`;

export const Title = styled.div`
  font-size: 14px; /* Tamanho de fonte reduzido para não sobrecarregar a tela */
  color: #B2B2B2;
  font-weight: 700;
  margin-top: 10px; /* Menor espaçamento */
`;

export const ArrowIcon = styled.div`
  background-color: #1270FC;
  padding: 8px; /* Menos padding para ajustar ao tamanho da tela */
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const BottomContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px; /* Menor espaçamento entre os itens */
`;

export const AmountContent = styled.div`
  font-size: 28px; /* Ajuste no tamanho da fonte */
  font-weight: bold;
  color: #444444;
  margin-bottom: 5px; /* Menor espaçamento inferior */
`;

export const ProgressBar = styled.div`
  display: flex;
  height: 3px; /* Altura reduzida para dar mais espaço */
  margin-top: 8px; /* Ajuste superior para maior proximidade com os itens acima */

  .filled {
    background-color: #007bff;
    width: 70%; /* Pode ajustar conforme a necessidade */
    border-radius: 2px;
  }

  .empty {
    background-color: #e0e0e0;
    flex: 1;
    border-radius: 2px;
  }
`;

export const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 12px; /* Ajuste de espaçamento superior */
`;

export const Percentage = styled.div`
  background-color: #d4f8e8;
  color: #ffffff;
  padding: 4px 6px; /* Menos padding para compactar o item */
  border-radius: 12px;
  font-size: 12px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TimeIndicator = styled.div`
  font-size: 12px;
  color: #ffffff;
  background-color: #1270FC99;
  padding: 4px 6px; /* Ajuste no padding */
  border-radius: 12px;
`;
